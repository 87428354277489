<template>
  <div class="cj-performance-s">
    <main v-if="state === 'ready'" class="container page">
      <ul class="nav nav-tabs" style="margin-top: 48px;">
        <li class="nav-item" v-for="ex in exams" :key="ex.id">
          <router-link 
            class="nav-link" 
            :to="{ name: 'performance_se', params: { student_id: student.id, exam_id: ex.id } }"
          >{{ex.compactTitle}}</router-link>
        </li>

        <li class="nav-item" style="flex-grow: 1"></li>
        <li class="nav-item">
          <router-link class="nav-link active" :to="{ name: 'performance_s', params: { student_id: student.id } }">Overall Performance</router-link>
        </li>
      </ul>

      <!-- <pre>{{gExams}}</pre> -->

      <div class="row">
        <div class="col-xs-12 xcol-lg-6 mt-4">
          <article ref="attemptHistoryContainer"></article>
        </div>
        <div class="col-xs-12 xcol-lg-6">

        </div>
      </div>

    </main>
    <main v-else class="container page">
      <h2>Loading...</h2>
      <pre>{{state}}</pre>
    </main>

  </div>
</template>

<script>
// import { AttemptColumns } from '@/entities'
import { AttemptColumns } from '@/entities/attempt.ent/attempt-columns'
import { ExamRepository, AttemptRepository, StudentRepository, gviz }  from '@/services'
const State = {
  loading: 'loading',
  error: 'error',
  ready: 'ready',
  empty: 'empty',
};

export default {
  name: 'StudentOverallPerformance',
  inject: ['gExams'],
  computed: {
    exams() {
      return this.gExams.length ? this.gExams : this.localExams;
    }
  },
  async created () {
    try{
      // let { student_id } = this.$route.params;
      const student_id = this.$route.params.student_id.includes(':') ? this.$route.params.student_id : 'Ao2S3rp9:meggie_heidenreich';
      this.student = await StudentRepository.findStudent(student_id);
      ExamRepository.findAll(); // side effect: populates reactive global gExams
      this.loadAllAttempts();
      this.setReady(); // peculiarly it is ready whilst loadAllAttempts() is still executing
    }
    catch(err){
      console.error(`Error loading student`, err);
      this.setError(err);
    }

  },
  
  data() {
    return {
      state: State.loading,
      error: null,
      // attempts: null,
      student: null,
      localExams: [
        { compactTitle: 'Ex1', id: 'ex1' }
      ],
    }
  },

  methods: {
    async loadAllAttempts() {
      console.info('loading all attempts');
      // how do I load this fella? 
      // well I have to perform a query for data? (but not manually, I should have attempts repository for that )
      const attemptsDt = await AttemptRepository.findBy({
        studentFullName: this.student.fullName,
        examId: undefined, // I want for all of the exams
      },{
        asDataTable: true,
        dataTableColumns: [
          AttemptColumns.COL_EXAM_NAME,
          // AttemptColumns.COL_EXAM_ID,
          // AttemptColumns.COL_FULL_NAME,
          AttemptColumns.COL_SCORE_PERCENT,
          AttemptColumns.COL_QUESTIONS_ANSWERED_CORRECTLY,
          AttemptColumns.COL_QUESTIONS_TOTAL,

          AttemptColumns.COL_TIME_STARTED_AT,
          AttemptColumns.COL_TIME_DURATION_MINUTES,
          AttemptColumns.COL_ID,
        ],
        initiatedBy: 'StudentOverallPerformance#loadAllAtempts()',
      });

      drawAttemptsHistoryTable(attemptsDt, this.$refs.attemptHistoryContainer);

      function drawAttemptsHistoryTable(attemptsDt, container){
        const { Table, DataView } = gviz;

        if ( !container ) return console.warn(`Cannot draw <Attempt History> because no container is specified.`);

        const attemptsDv = new DataView(attemptsDt);

        const table = new Table(container);
        table.draw(attemptsDv, {
          width: '100%',
        })

      }
      
    },

    setError(e){
      this.state = State.error;
      this.error = e;
    },

    setReady(){
      this.state = State.ready;
    }
  },
}
</script>

<style scoped>
.cj-performance-s{
  border: 1px solid gold;
}

</style>