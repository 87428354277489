import { BaseRepository } from './base-repository'
import { mapDataTableRows } from './utils'
import { Exam, ExamColumns } from '@/entities'
import { toAlpha } from '@/utils/utils'

export class ExamRepository extends BaseRepository {
  constructor(params){
    super(params);
    this.gExams = params.gExams;
  }

  async findAll({ ord = '' } = {}, qOpt = {}){
    // const STARTED_AT = toAlpha(AttemptColumns.COL_TIME_STARTED_AT);
    // const sql = !ord ? `select *` : `select * order by ${STARTED_AT} ${ord}`;
    const sql = `select *`;
    // return mapDataTableRows(await this.query(sql, qOpt)).map(Exam.fromDataRow);
    const allExams = (await this.query(sql, qOpt)).mapRow(Exam.fromDataRow);
    // this.gExams.splice(0, -1).push(...allExams); // will each of the exams be reactively wrapped?
    this.gExams.splice(0, this.gExams.length);
    this.gExams.push(...allExams); // will each of the exams be reactively wrapped?
    return allExams;
  }

  // async findBy({ examId }, qOpt = {}){
  //   const asDataTable = Boolean(qOpt.asDataTable);
  //   const operands = [];
  //   const sql = this._compileQuery([
  //     `select *`,
  //     operands,
  //   ]);
  //   const dataTable = await this.query(sql, qOpt);
    
  //   // we do this idiom (of extracting single function),
  //   // 'cos there another idiom: of calling 
  //   // eg. const fromDataRow = Exam.µFromDataRow({ params});
  //   const fromDataRow = Exam.fromDataRow; 

  //   return asDataTable ? dataTable : dataTable.mapRow(fromDataRow);
  // }

  async findOneById({ exam_id }, qOpt = {}){
    const EXAM_ID = toAlpha(ExamColumns.COL_EXAM_ID);
    const sql = `select * where ${EXAM_ID} = '${exam_id}' LIMIT 1`;
    const dataTable = await this.query(sql, qOpt);
    const rows = mapDataTableRows(dataTable); // map against what?
                                              // can I use dataTable.mapRows() 
                                              // or dataTable.rows()
                                              // or dataTable.toRows()
    if ( 0 === rows.length ){
      return null;
    }
    console.assert(1 === rows.length, `Query with LIMIT 1 should return just single row of results when querying within ExamRepository#findOneById(${exam_id})`);

    // now let's unmarshal into entity (aka inflate entity)
    const fromDataRow = Exam.fromDataRow;
    const exams = rows.map(fromDataRow);
    return exams[0];
  }

  static async createAsync(params){
    return new ExamRepository(params);
  }  

}