<template>
  <div class="cj-exam-list page container">
    <table class="table table-hover cj-exam-table">
      <thead>
        <th>#</th>
        <th>Id</th>
        <th>Title</th>
        <th>CompactTitle</th>
        <th>Kind</th>
        <th>Children (ids)</th>
      </thead>
      <tbody>
        <tr v-for="(ex,i) in exams" :key="ex.id">
          <td>{{i+1}}</td>
          <td>{{ex.id}}</td>
          <td>{{ex.title}}</td>
          <td>{{ex.compactTitle}}</td>
          <td>{{ex.kind}}</td>
          <td>{{ex.children}}</td>
        </tr>
      </tbody>
    </table>

  </div>
</template>

<script>
import { StudentRepository, ExamRepository } from '@/services'
export default {
  name : 'ExamList',
  created () {
    this.loadExams();
  },
  data() {
    return {
      exams: [],
    }
  },
  methods: {
    async loadExams() {
      // StudentRepository.listExams();
      this.exams = await ExamRepository.findAll();
      // this.exams = [
      //   { id: 'ex1', title: 'Exam 1' }
      // ];
    }
  },
}
</script>

<style scoped>

</style>