import gql from 'graphql-tag'
export const LIST_ITEMS_OF_STUDENT_GROUP = gql`query ListItemsOfStudentGroup($groupUuid: String!) {
  students: listItemsOfStudentGroup(group_uuid: $groupUuid) {
    uuid
    first_name
    last_name
    email
    graduation
    school
    state
  }
}

`;