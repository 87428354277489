<template>
  <section class="co-panel">
    <h3>Student groups</h3>
    <ul class="co-student-groups-list">
      <li v-for="sgr in studentGroups" 
          v-bind:key="sgr.uuid" 
          class="co-student-groups-list__item"
          >
          <router-link 
            xto="/lab/student-groups/{{sgr.uuid}}"
            :to="'/lab/student-groups/' + sgr.uuid"
            >
            {{sgr.name}}
          </router-link>
        </li>
    </ul>
  </section>
</template>

<script>
import gql from 'graphql-tag'
const LIST_STUDENTGROUPS = gql`query studentGroups {
  studentGroups:  listStudentGroups {
    uuid
    name
    tenant_uuid
  }
}`
export default {
  name: 'StudentGroupsList',
  apollo: {
    studentGroups: LIST_STUDENTGROUPS,
  },
  created () {
    // this.loadStudentGroups();
  },
  data() {
    return {
      students: [],
      studentGroups: [], // do I need this, provided I already have the apollo key? 
    }
  },
  methods: {
    handleStudentClick(st) {
      alert('Selected student: ' + st.uuid);
    },
    async loadStudentGroups(){
      const apolloQueryResult = await this.$apollo.query({
        query: LIST_STUDENTGROUPS,
      });
      console.log(`apolloQueryResult: `, apolloQueryResult);
      if ( apolloQueryResult.error ){
        console.error(apolloQueryResult.error);
        throw new Error(apolloQueryResult.error.name);        
      }

      const { studentGroups } = apolloQueryResult.data;
      debugger;
      this.studentGroups = studentGroups;

    }
  },
}
</script>

<style scoped>
.co-panel{ 
  border: 1px solid gold;
}
</style>