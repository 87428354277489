import gql from "graphql-tag";
export const INVITATIONS_OF_TENANT = gql`query ListInvitationsOfTenant($tenantUuid: String!) {
  invitations: listInvitationsOfTenant(tenant_uuid: $tenantUuid) {
    uuid
    status
    destination_email
    tenant_uuid
    created_at
    updated_at
    synced_at
    last_sent_at
    sent_history
    user_uuid
    user_uuid_external
    invitation_message_text
    invitation_message_html
  }
}`;