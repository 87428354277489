<template>
  <div v-if="lastAttempt" class="cj-widget-last-score widget-small widget-small--round">
      <div class="cj-widget-last-score__textual-report">
        <span class="widget-small__title">Last attempt</span>
        <span class="widget-small__percentage">
          {{lastAttempt.ScorePercent}}%
        </span>
        <span class="widget-small__hint">
          Answered 
            {{lastAttempt.QuestionsAnsweredCorrectly}}
          / 
          {{lastAttempt.QuestionsTotal}}
        </span>
        <span class="widget-small__hint">
          <!-- on {{lastAttempt.TimeSubmittedAt}} -->
          on {{lastAttemptDate}}
        </span>
        <span class="widget-small__title">
          <!-- on {{lastAttempt.TimeSubmittedAt}} -->
          Duration {{lastAttempt.TimeDurationMinutes}} minutes
        </span>
      </div>
      <div ref="gaugeContainer">
        Loading ...
      </div>

  </div>
  <div v-else class="widget-small" style="padding: 32px; text-align: center;">
    <span>Student did not attempt this exam yet...</span>
  </div>
</template>

<script>
  import { gviz } from '@/services'
  import { format } from 'date-fns'
  export default {
    name: 'LastScoreWidget',
    computed: {
      lastAttemptDate(){
        return format(this.lastAttempt.TimeSubmittedAt, 'MMMM d');
      }
    },
    created () {
      // this.drawGauge(); // doesn't draw from here as dom and ref is not ready
    },
    props: {
      lastAttempt: {
        type: Object,
        // default: ()=>{}, // 
      },
    },
    methods: {
      drawGauge() {
        const { Gauge, arrayToDataTable } = gviz;
        console.log(`gaugeContainer:`, this.$refs.gaugeContainer);
        const gauge = new Gauge(this.$refs.gaugeContainer);
        const data = arrayToDataTable([
          ['Label', 'Value'],
          ['Score', this.lastAttempt.ScorePercentInt],
          // ['Memory', 80],
          // ['CPU', 55],
          // ['Network', 68]
        ]);

        gauge.draw(data, {
          height: 220,
          width: 220,
          redFrom: 0,
          redTo: 50,
          yellowFrom: 50,
          yellowTo: 80,
        });
             
      }
    },
    mounted () {
      this.drawGauge();

    },
    watch: {
      lastAttempt(newValue, oldValue) {
        if ( null == newValue ){
          // can we clean the gauge? 
          this.$refs.gaugeContainer.innerHTML = '';
          return;
        }
        this.drawGauge();
      }
    },
  }
</script>

<style scoped>
.cj-widget-last-score{
  display: flex;
  /* background: #e1e1e1; */
  background: #0dcaf0;
  flex-direction: row;
  justify-content: space-evenly;  
}

.cj-widget-last-score__textual-report{
    display: flex;
    flex-direction: column;
    align-items: center;
}

</style>