<template>
    <nav class="breadcrumb-wrapper" aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="#/">Home</a></li>
        <li v-for="(crumb,i) in crumbs" :key="i" class="breadcrumb-item">

          <a v-if="crumb.to" :href="crumb.to">{{crumb.name}}</a>
          <span v-else>{{crumb.name}}</span>
        </li>

        <!-- <li class="breadcrumb-item"><a href="#">Home</a></li>
        <li class="breadcrumb-item"><a href="#">Library</a></li>
        <li class="breadcrumb-item active" aria-current="page">Data</li>
        <li class="breadcrumb-item">{{route.path}}</li> -->
      </ol>
      <span></span>
    </nav>
</template>

<script>
  export default {
    name: 'CjBreadcrumbs',
    computed: {
      route() {
        return this.$route; 
      },
      crumbs(){
        return this.$route.meta.crumbs ? this.$route.meta.crumbs(this.$route) : [{ name: (this.$route.name || 'ROUTE_HAS_NO_NAME_OR_CRUMBS').toLowerCase() }];
      }
    },
    created(){
      // console.log(`this.$route.params`, this.$route.params);
      console.log(`Breadcrumbs#created(): this.$route`, this.$route);
    },
    watch: {
      // // This one is only called once
      // $route(to, from) {
      //   console.log(`>> route_change to `, to);
      // }
    },
  }
</script>

<style scoped>
.breadcrumb > .breadcrumb-item > span {
  text-transform: capitalize;
}
</style>