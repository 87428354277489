<template>
  <div class="co-tenant-list">
    <section v-if="tenants">
      <article
        v-for="tn of tenants"
        :key="tn.id"
        class="co-tenant-list__item xco-tenant-list__item--selected"
        :class="{
          'co-tenant-list__item--selected': tn.__isSelected,
        }"
        @click="handleTenantClick(tn)"
      >
        <span class="co-tenant-list__item__icon">
          <i class="fa fa-suitcase fa-2x" />
        </span>
        <span :title="tn.uuid" class="co-tenant-list__item__title">
          {{ tn.title }}
        </span>
        <span class="co-tenant-list__item__column">
          {{ tn.is_active }}
        </span>
        <span class="co-tenant-list__item__column">
          {{ tn.kind }}
        </span>
      </article>
    </section>

    <section v-else>
      No tenants yet
      <div v-if="isLoading" class="progress">
        <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style="width: 75%"></div>
      </div>
    </section>
  </div>
</template>

<script>
import { LIST_TENANTS } from "../gql/list-tenants.gql";
export default {
  name: "TenantList",
  // apollo: {
  //   tenants: LIST_TENANTS, // this shortcut should work
  // },
  computed: {
    isLoading() {
      return this.$apollo.loading;
    }
  },

  data() {
    return {
      // isLoading: false,
      loadingMessage: `Loading tenants...`,
      // tenants: [
      //   { label: 'Bob', id: 'bob1' },
      //   { label: 'Bob2', id: 'bob2' }
      // ],
    };
  },

  methods: {
    handleTenantClick(tenant) {
      // alert(`Clicking tenant: ${tenant.title}(${tenant.uuid})`)
      this.tenantsSetSelected(tenant.uuid);
      this.$emit('onTenantSelected', { ...tenant }); // what kind of object is going to be passed? a copy?
      
      // the thing on the bottom didn't seem to affect things
      // this.$nextTick(()=>{
      //   this.$emit('onTenantSelected', { ...tenant }); // what kind of object is going to be passed? a copy?
      // })
      // need to trigger event up the stream
    },
    tenantsSetSelected(tenant_uuid) {
      this.tenantsClearSelected();

      // not sure if emit actually can return a value...
      // (actually most likely it cannot)
      this.$emit('tenantMutation', {
        op: 'setSelected',
        tenant_uuid, 
        subject: 'item', // can be 'collection' | 'item'
      });      
    },
    tenantsClearSelected() {
      this.$emit('tenantMutation', {
        op: 'clearSelected',
        subject: 'collection', // can be 'collection' | 'item'
      });
      // this.tenants.forEach((tn) => (tn.__isSelected = false));
    },
  },
  mounted() {
    // what should we do here?
  },
  props: {
    tenants: {
      type: [Array],
      required: true,
      // default:
    },
  },
};
</script>

<style scoped>
.co-tenant-list {
  border: 1px solid gold;
}

.co-tenant-list__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 16px;
  height: 48px;
  cursor: pointer;
}

.co-tenant-list__item:hover {
  background: rgb(40 181 248 / 13%);
}

.co-tenant-list__item--selected,
.co-tenant-list__item--selected:hover {
  background: rgba(76, 191, 245, 0.813);
}

.co-tenant-list__item__icon {
  opacity: 0.3;
  padding-right: 8px;
}
.co-tenant-list__item__title {
  flex-grow: 1;
}
</style>
