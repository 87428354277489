// import { keyBy, isUndefined, isString, get } from 'lodash-es'
// import { categoryMetaDefinitionsIndexedById } from './lib/category-metadata'
import { µFromDataRow } from './attempt.ent/from-data-row'

export class Attempt{

  constructor({
    StudentAttempt, // id
    ExamName,
    ExamId,
    TimeStartedAt,
    TimeSubmittedAt,
    StudentFullName,
    // ScorePercent,
    QuestionsAnsweredCorrectly,
    QuestionsSkipped,
    QuestionsTotal,
    TimeDurationSeconds = null, // PROBLEM: BUT GOOGLE SHEET INCLUDES THIS ONE!!!!
                                // can be null (not specified) then 
                                // we automatically calculate it
                                // but we can also specify it explicitly.

  }){

    
    // Automatically initialize fields from the params
    this.fields = `StudentAttempt	ExamName	ExamId	StudentFullName	ScorePercent	QuestionsAnsweredCorrectly	QuestionsSkipped QuestionsTotal	TimeStartedAt	TimeSubmittedAt	TimeDurationMinutes	TimeDurationSeconds`.split(/\s+/).map(s=>s.trim()).filter(Boolean);

    const params = Array.from(arguments)[0];

    // TODO: shouldn't this be inside Atempt.fromDataRow() .... where we can handle GoogleSheets related logic...
    // {Automatic Assignment}
    // these are guards, which do not bring what should be calculated properties 
    // but they are "hardcoded" into the source Google Sheet for demonstration purposes
    // (It's fine that they are excluded from {automatic assignment} as even TimeDurationSeconds
    //  i want to handle separately )
    for(let fname of this.fields){
      if ( 'ScorePercent' === fname ) continue; // let's exclude calculated property
      if ( 'TimeDurationMinutes' === fname ) continue; // let's exclude calculated property
      if ( 'TimeDurationSeconds' === fname ) continue; // let's exclude calculated property
      this[fname] = params[fname];
    }

    this._TimeDurationSeconds = TimeDurationSeconds;

    // Manually initialize fields from params (this is more for typehinting)
    this.ExamName = ExamName;
    this.ExamId = ExamId;
    /** @type {Date} */
    this.TimeStartedAt = TimeStartedAt;
    /** @type {Date} */
    this.TimeSubmittedAt = TimeSubmittedAt;
    this.StudentFullName = StudentFullName;
    this.QuestionsAnsweredCorrectly = QuestionsAnsweredCorrectly;
    this.QuestionsSkipped = QuestionsSkipped;
    this.QuestionsTotal = QuestionsTotal;
    // this.ScorePercent = ScorePercent;

    // Manually Initialize some internal fields
    this.StudentAttempt = StudentAttempt /* ||  rand_id('at_'); */;

    // console.log(`Creating attempt(${this.id}) for student '${this.StudentFullName}'`);
    // console.log(`with fields: `, this.fields);
  }

  get QuestionsAnsweredIncorrectly(){
    return this.QuestionsTotal - this.QuestionsSkipped - this.QuestionsAnsweredCorrectly;
  }

  get id(){
    return this.StudentAttempt;
  }

  // Public scores
  get ScorePercent(){
    return Number((this.QuestionsAnsweredCorrectly/ this.QuestionsTotal*100).toFixed(1));
  }

  get ScorePercentInt(){
    return Math.floor(this.ScorePercent);
  }  

  get TimeDurationMinutes(){
    // how to have a diff between two dates.
    // return 42;
    return Number((this.TimeDurationSeconds / 60).toFixed(1));
  }

  get TimeDurationSeconds(){
    // return this.TimeDurationMinutes * 60;
    if ( this._TimeDurationSeconds ){  // allows {explicit passing} for calculated fields
      return this._TimeDurationSeconds;
    }
    return (this.TimeSubmittedAt.getTime() - this.TimeStartedAt.getTime() ) / 1000;
  }

  setTimeStartedAt(time){
    this.TimeStartedAt = time;
  }

  setTimeSubmittedAt(time){
    this.TimeSubmittedAt = time;
  }

  /** 
   * @param {Exam} exam
   */
  setExam(exam){
    this._exam = exam;
    this.ExamName = exam.name;
    this.ExamId = exam.id;
    this.QuestionsTotal = exam.questionsTotal;
  }

  toArray(){
    return this.fields.map(fname=>{
      return this[fname];
    })
  }

  toArrayOfFieldnames(){
    return Array.from(this.fields);
  }  

  toString(){
    return `Atempt(${this.id})`;
    // return `Atempt(${this.id}){` + this.fields.join(',') + `}`;
  }


  toJSON(){
    const {
      StudentAttempt, // this is Id
      id, // same as StudentAttempt
      ExamName,
      ExamId,
      TimeStartedAt,
      TimeSubmittedAt,
      StudentFullName,
      QuestionsAnsweredCorrectly,
      QuestionsTotal,
      QuestionsSkipped,
      QuestionsAnsweredIncorrectly,
      ScorePercent,
      ScorePercentInt,
      TimeDurationMinutes,
      TimeDurationSeconds,

    } = this;
    return {
      // StudentAttempt, // this is Id
      id, // same as StudentAttempt 
          // TODO: we have to figure out how do we 
          //       sort out the inconsistent naming problem for ID field (lowercase/camelcase)
      ExamName,
      ExamId,
      TimeStartedAt,
      TimeSubmittedAt,
      StudentFullName,
      QuestionsAnsweredCorrectly,
      QuestionsTotal,
      QuestionsSkipped,
      QuestionsAnsweredIncorrectly,
      ScorePercent,
      ScorePercentInt,
      TimeDurationMinutes,
      TimeDurationSeconds,      

    };
  }

  // -------------------------------------------
  // Retrievers: 
  // (utility methods - kind of accessors by where you can do queries and filtering)
  // -------------------------------------------
  /**
   * 
   * @param {String} catId 
   * @returns {Category}
   */
  retrieveCategory(catId){
    if ( null === this._categories ) throw new Error(`Categories data is not defined and in unknown state`);
    return this._categories[catId];
  }

  hasCategories(){
    if ( null === this._categories ) throw new Error(`Categories data is not defined and in unknown state`);
    return false !== this._categories;
  }

  /**
   * 
   * @param {Object|false} categoriesIndexedById you can set <false> value to show that there are NO categories defined for this attempt
   */
  setCategories(categoriesIndexedById){
    this._categories = categoriesIndexedById;
  }
  
  
  // -------------------------------------------
  // statics
  // -------------------------------------------

  /**
   * @returns {(rowData: object)=>Attempt} function fromDataRow
   */
  static µFromDataRow(...args) {
    return µFromDataRow(...args);
  }

  static fromDataRow(rowData){
    throw new Error(`Please switch from Attempt#fromDataRow(rowData) to  calling factory function Attempt#µFromDataRow({ categories })(rowData)`);
  }


  /**
   * @param {Attempt} a
   * @param {Attempt} b
   * 
   */
  static cmpByTimeStartedAt(a,b){
    return a.TimeStartedAt.getTime() - b.TimeStartedAt.getTime();
  }
  static cmpByTimeStartedAtReverse(a,b){
    return b.TimeStartedAt.getTime() - a.TimeStartedAt.getTime();
  }
}
