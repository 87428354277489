<template>
  <div class="cj-attempt-list container page">
     <table class="table table-hover attempt-table">
       <thead class="table-light">
        <tr>
          <th>#</th>
          <th>Attempt Id</th>
          <th>Student Name</th>
          <th>Exam Id</th>
          <th>Exam Name</th>
          <th>Score Percent</th>
          <th>Actions</th>
        </tr>
       </thead>

       <tbody>

        <tr v-for="(att,i) of attempts" v-bind:key="att.id">
          <td>{{i}}</td>
          <td>{{att.id}}</td>
          <td>{{att.StudentFullName}}</td>
          <td>{{att.ExamId}}</td>
          <td>{{att.ExamName}}</td>
          <td>{{att.ScorePercent}}</td>
          <td>
            <button class="btn btn-brand btn-sm" @click="doOpen">Open</button>
          </td>
        </tr>
      </tbody>
      </table>
  </div>
</template>

<script>
import { StudentData, AttemptRepository } from '../../services'

export default {
  name: 'AttemptList',
  props: {
    prefix : String,
  },

  created(){
    // this.loadStudents()
    this.loadAttempts();
  },

  data() {
    return {
      attempts: [],
      students: [],
    }
  },

  methods: {
    selectStudent(st) {
      alert(`Selected student: ${st.name}`);
    },

    doOpen(){
      alert('Do open');
    },

    async loadStudents(){
      this.students = await StudentData.listStudents();
    },

    async loadAttempts(){
      this.attempts = await AttemptRepository.findAll({}, { initiatedBy: 'AttemptList#loadAttempts()'})
    }
  },



}
</script>

<style scoped>
.cj-attempt-list{
  border: 0 solid transparent;
}
.student-table, .attempt-table {
  min-width: 400px;
  border: 1px solid gainsboro;
}

</style>

