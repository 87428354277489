import { range, isNil } from 'lodash-es'

/*
* @param {*} params 
* @return {String}
*/
export function makeSheetUrl({ sheetId, gid, headers = '' }){
  if ( isNil(sheetId) ) return null;
  if ( isNil(gid) ) return null;
 let url =  `https://docs.google.com/spreadsheets/d/${sheetId}/edit?gid=${gid}`;
 if ( headers ){
   url += `&headers=${headers}`;
 }
 return url;
}


/**
 * 
 * @param {google.visualization.DataTable} dt 
 */
  export function mapDataTableRows(dt){
  // @see DataTable https://developers.google.com/chart/interactive/docs/reference#methods
  const columns = range(0,dt.getNumberOfColumns()).map(colIndex=>{
    return {
      colIndex,
      label: dt.getColumnLabel(colIndex),
      id: dt.getColumnId(colIndex), // i don't know what kind of value 'id' actually is...
      extractFromRow(rowIndex){
        return dt.getValue(rowIndex, this.colIndex);
      },
    };
  })
  const rowToEntity = (rowIndex) => {
    let entity = {};
    for(let colDef of columns){
      entity[colDef.colIndex] = entity[colDef.label] = colDef.extractFromRow(rowIndex);
    }
    return entity;
  };

  const allEntities = range(0, dt.getNumberOfRows()).map(rowToEntity);
  return allEntities;
}


