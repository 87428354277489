import gql from "graphql-tag";
export const MEMBERS_OF_TENANT = gql`query ListMembersOfTenant($tenantUuid: ID!) {
  members: listMembersOfTenant(id: $tenantUuid) {
    uuid
    email
    title
    tenant_uuid
    created_at
    updated_at
    deleted_at
  }
}
`;