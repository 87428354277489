<template>
  <div class="tenant-list">
    <header class="tenant-list__header">
      <span class="tenant-list__header-title">Invitations to join organization</span>
      <span class="tenant-list__header-actions">
        <a href="#" @click="handleCreateInvitation" class="btn btn-link">
          +invite faculty member
        </a>
      </span>
    </header>
      <ul v-if="invitations.length" class="scroll">
        <li v-for="inv of invitations" :key="inv.uuid" :title="inv.uuid">{{inv.label}}</li>
      </ul>
      <div v-else>
         No invitations found.
      </div>
  </div>
</template>

<script>
import { MUTATION__CREATE_INVITATION } from '../gql/create-invitation.gql'
import swal from 'sweetalert'
const $$ = (target, label)=> {
  if ( label ) {
    console.warn(label, target);
  }
  else{
    console.warn(target);
  }
  return target;
}
export default {
  props: {
    invitations: {
      type: [Array],
      required: true,
    },
    /** @type {Tenant} */
    tenant: {
      // type: [Object], // not sure if I can put [Object] into square brackets
      type: Object, // is this how I declare object? 
      required: true,
      // default: ()=>{},

    },
  },
  methods: {
    async createNewInvitation(inputInvitation){
      const {
        destination_email,
        tenant_uuid,
        invitation_message_html,
        invitation_message_text,
       } = inputInvitation;

       debugger;
       const mutationResult = $$(await this.$apollo.mutate({
        mutation: MUTATION__CREATE_INVITATION,
        variables: {
          inputInvitation,
          // destination_email,
          // tenant_uuid,
          // invitation_message_html,
          // invitation_message_text,          
        },
       }));

       debugger;
       return mutationResult.data.newInvitation;

    },
    async handleCreateInvitation(ev) {
      ev.preventDefault();
      const { tenant } = this;
      // how can I create invitation? 
      // i need to request email address of the faculty member
      const inputInvitation = {
        // "destination_email": "dimkir+rdhstat16@gmail.com",
        "destination_email": "",
        // "tenant_uuid": "te25OLbWiTXv",
        "tenant_uuid": tenant.uuid,
        "invitation_message_html": null,
        "invitation_message_text": null
      };
      inputInvitation.destination_email = await swal(
        `Type email address of the person you want to receive invitation into your orgniaztion. ` 
        + `\n\nThey will receive email with temporary password and will be ` 
        + `able to login into “${tenant.title}” and view all it's students and their performance.`, {
        title: `Who do you want to invite into “${tenant.title}”?`,
        content: 'input',
        closeOnEsc: true,
        closeOnClickOutside: false,
        buttons: [
          'Cancel', // will return null
          'Next',  // will confirm
        ],
      });

      if ( null === inputInvitation.destination_email  ){
        return;
      }

      inputInvitation.destination_email = inputInvitation.destination_email.trim();

      console.log(`After sanitizing: ` + JSON.stringify(inputInvitation, null, 2));
      const newInvitation = await this.createNewInvitation(inputInvitation);
      console.log(`new Invitation: ${JSON.stringify(newInvitation, null,2)}`);
      await swal(
        `Invitation sent`,
        `We have sent invitation to user with email ${inputInvitation.destination_email}`,
        'success'
      );
      // TODO: how to add this to the collection of invitations???
      // TODO: how to add this to the collection of invitations???
      // TODO: how to add this to the collection of invitations???
      // TODO: how to add this to the collection of invitations???
      // TODO: how to add this to the collection of invitations???
      //       i probably need to emit something...
    }
  },
  
}
</script>

<style scoped>

.tenant-list__header{
}

.tenant-list__header-actions{
  flex-grow: 1;
  text-align: right;
}


</style>