<template>
  <div class="cj-widget-attempt-category-breakdown-gc widget-small widget-small--full-height widget-small--tight" ref="tableChartContainer">
    <h3>Loading...</h3>
    <!-- <h3>{{ lastAttempt.id }} </h3> -->
    <!-- <button class="btn btn-primary" @click="renderGoogleTable">render Table</button>
    <pre>[{{lastAttempt}}]</pre> -->

  </div>
</template>

<script>
import { Attempt, Category } from '../../../entities/attempt.ent'
import { StudentRepository, AttemptRegistry, gviz } from '../../../services'

export default {
  name: 'AttemptCategoryBreakdownGc',
  mounted () {
    this.initialize();

  },

  methods: {
    initialize(resetFirst = false){
      if ( resetFirst ){
        const el = this.$refs.tableChartContainer;
        el.innerHTML =`<h3>Reloading widget...</h3>`;
        // while(el.lastChild) el.removeChild(el.lastChild);
        // return;
      }
      // const { Query, QueryResponse, QueryWrapper, CustomQuery, DataTable, Table } = gviz;
      const { DataTable, Table, ColorFormat } = gviz;

      // we need to get data...
      // what else? 
      // now render
      // for DataTable @see https://developers.google.com/chart/interactive/docs/reference#DataTable
      const dt = new DataTable(); 
      dt.addColumn('string', 'Category');
      dt.addColumn('number', 'Score');
      dt.addColumn('number', 'Correct / Total'); 
      /** @type {Attempt} */
      const attempt = this.lastAttempt;

        
      for(let [catId, catMetric] of Object.entries(attempt._categories)){
        /** @type {Category} metric */ const metric =  catMetric;
        // dt.addRow([metric.title, metric.scorePercent]);
        dt.addRow([
          metric.title, 
          { v: metric.scorePercentInt, f: `${metric.scorePercentInt}%`},
          { v: metric.scorePercentInt, f: `${metric.answeredCorrectly} / ${metric.total}`}, // we maintain v:scorePercentInt, 'cos we want sort to work predictably
        ]);
        
      }
      const formatter = new ColorFormat();
      // formatter.addRange(null, 50, 'white', '#ff678a');
      formatter.addRange(null, 50, 'white', 'orange');
      formatter.addRange(50, 80, 'white', '#208919');
      formatter.addRange(80, null, 'white', '#33ff33');
      formatter.format(dt, 1); // eg. formatter.format(dataTable, 1) apply to 2nd column (zero-indexed)!
      const tableChart = new Table(this.$refs.tableChartContainer);
      tableChart.draw(dt, {
        // showRowNumber: true,
        // title: 'Category Breakdown', // this doesn't work neither
        allowHtml: true, 
        width: '100%',
        // fontName: 'Times', // this doesn't work 
      });
    },
    renderGoogleTable() {
      alert(`this.$refs.tableChartContainer ${this.$refs.tableChartContainer}`);
    },
  },

  props: {
    lastAttempt: Object,
  },
  watch: {
    lastAttempt(newValue, oldValue) {
      console.log(`AttemptCategoryBreakdownGc.watch::lastAttempt/oldValue`, oldValue);
      console.log(`AttemptCategoryBreakdownGc.watch::lastAttempt/newValue`, newValue);
      if ( null === newValue ){
        this.$refs.tableChartContainer.innerHTML = `<h3>Student has not taken this exam yet</h3>`;
        return;
      }
      this.initialize(true);
    }
  },

}
</script>

<style scoped>

.cj-widget-attempt-category-breakdown-gc{

}

</style>