<template>
  <div class="cj-attempt-detail">
    <h2>AttemptDetail</h2>
    <pre>{{this.$route.params}}</pre>
  </div>
</template>

<script>
// import 
export default {
  name: 'AttemptDetail',
  created () {
    console.log(`$route.params`, this.$route.params);
  },

}
</script>

<style scoped>
.cj-attempt-detail{
  background: skyblue;

}
</style>