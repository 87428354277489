import { BaseRepository } from './base-repository'
import { mapDataTableRows } from './utils'
import { toAlpha } from '@/utils/utils'
import { Attempt } from '../entities/attempt.ent'
import { AttemptColumns } from '../entities/attempt.ent/attempt-columns'

// import { categoryMetaDefinitionsIndexedById, categories as categoryDefinitions } from '../entities/lib/category-metadata'
import { apolloClient } from '../apollo-client'
import { gql} from 'graphql-tag'


// const ALL = Object.values(COLS).join(', ');

export class AttemptRepository extends BaseRepository {
  /**
   * @param {object} params
   */
  constructor(params){
  // constructor(params){
    // super(...Array.from(arguments));
    super(params);
    this._categoryDefinitions = null; /* will be lazy loaded */
  }

  async categoryDefinitions(){
    if ( null === this._categoryDefinitions ){
      this._categoryDefinitions = await this._loadCategoryDefinitions();
    }
    return this._categoryDefinitions;
  }

  async _loadCategoryDefinitions(){
    // return categoryDefinitions;
    const { data, error } = await apolloClient.query({
      query: gql`query Categories_list {
        categoryDefinitions:  listCategories {
          id
          title
          imageFilename
          imageUrl
        }
      }`,

    });
    if ( error ){
      throw error;
    }
    return data.categoryDefinitions;
  }

  async findAll({ ord = '' } = {}, qOpt = {}){
    const STARTED_AT = toAlpha(AttemptColumns.COL_TIME_STARTED_AT);
    const sql = !ord ? `select *` : `select * order by ${STARTED_AT} ${ord}`;
    const fromDataRow = Attempt.µFromDataRow({
      categoryDefinitions: await this.categoryDefinitions()
    });
    return mapDataTableRows(await this.query(sql, qOpt)).map(fromDataRow);
  }

  /**
   * Queries for single attempt
   * @param {object} params
   * @param {String} params.fullName
   * @returns {Promise<null|Attempt>}
   */
  async findOneByUser({ fullName }, qOpt = {}){
    const FULL_NAME = toAlpha(AttemptColumns.COL_FULL_NAME);
    const STARTED_AT = toAlpha(AttemptColumns.COL_TIME_STARTED_AT);
    const sql = `select * where ${FULL_NAME} = '${fullName}' ORDER BY ${STARTED_AT} LIMIT 1`;
    const dataTable = await this.query(sql, qOpt);
    const rows = mapDataTableRows(dataTable);
    const fromDataRow = Attempt.µFromDataRow({
      categoryDefinitions: await this.categoryDefinitions()
    });    
    const attempts = rows.map(fromDataRow);    
    if ( 0 === attempts.length ){
      return null;
    }
    if ( 1 !== attempts.length ){
      throw new Error(`Logic error - query [${sql}] for single element returned ${attempts.length} results.`);
    }
    return attempts[0];
  }

  async findByUser({ fullName = '', id = '' }, qOpt = {}){
    return await this.findAttemptsByUser({ fullName, id }, qOpt);
  }

  /**
   * @param {Object} qOpt (optional)
   * @param {Boolean} qOpt.asDataTable
   */
  async findBy({ studentFullName, examId }, qOpt = {}){
    const asDataTable = Boolean(qOpt.asDataTable);
    const operands = [
      [studentFullName, AttemptColumns.COL_FULL_NAME, toAlpha(AttemptColumns.COL_FULL_NAME)],
      [examId, AttemptColumns.COL_EXAM_ID, toAlpha(AttemptColumns.COL_EXAM_ID)],
    ];
    const sql = this._compileQuery([
      `select *`,
      operands,
    ]);
    const dataTable = await this.query(sql, qOpt);
    // const dataTable = await this.query(`select *` 
    //   + ` where ${toAlpha(AttemptColumns.COL_FULL_NAME)} = '${studentFullName}'`
    //   + ` and ${toAlpha(AttemptColumns.COL_EXAM_ID)} = '${examId}'`
    // , qOpt);
    const fromDataRow = Attempt.µFromDataRow({
      categoryDefinitions: await this.categoryDefinitions()
    });

    return asDataTable ? dataTable : dataTable.mapRow(fromDataRow);
  }

  /**
   * Queries Attempts storage to find attempts by attempt id or 
   * Student fullName, returns empty array when none found.
   * @param {Object} param
   * @param {String} param.fullName fullName of a Student
   * @param {String} param.id id of Attempt !!! (not id of a Student)
   * @param {*} qOpt 
   * @returns {[]|Attempt[]} returns empty
   */
  async findAttemptsByUser({ fullName = '', id = '' }, qOpt = {}){
    // const { NAME, AGE, ID } = COLS;

    let dataTable;
    // we can query one or the other 
    if ( fullName && id ){
      throw new Error(`To query Attempt Repository via findAttemptsByUser() You must query either by 'fullName' or by 'id' but not both.`);
    }
    if ( id ){
      dataTable = await this.query(`select * where ${toAlpha(AttemptColumns.COL_ID)} = '${id}' `, qOpt);
      // dataTable = this.query(`select ${ALL} where ${toAlpha(AttemptColumns.COL_ID)} = '${id}' `);
      // dataTable = this.query(`select [*] where [StudentId] = '${ID}' `);
    }
    else if ( fullName ){
      dataTable = await this.query(`select * where ${toAlpha(AttemptColumns.COL_FULL_NAME)} = '${fullName}' `, qOpt);
    }
    else {
      throw new Error(`You must set either 'id' or 'fullName' properties to query AttemptRepository`);
    }
    
    const rows = mapDataTableRows(dataTable);

    const fromDataRow = Attempt.µFromDataRow({
      categoryDefinitions: await this.categoryDefinitions()
    });

    const attempts = rows.map(fromDataRow);
    return attempts;
    
  }

  static async createAsync(params){
    return new AttemptRepository(params);
  }
}