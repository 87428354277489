import { isNil } from 'lodash-es'

export function notNilAll(...args){
  return args.every(el=>!isNil(el));
}

export function areAllButSingleValuesNil(...args){
  const count = args.reduce((count, v)=>{
    if ( !isNil(v) ) count++;
    return count;
  }, 0);

  return 1 === count;
}

export function toAlpha(n){
  return String.fromCharCode(n + 65);
}