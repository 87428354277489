<template>
  <div class="tenant-list">
    <header>
      <span>Organization users</span>
    </header>
      <ul v-if="members.length" class="scroll">
        <li v-for="u of members" :key="u.uuid" :title="u.uuid">
          {{u.label}} - {{u.email}} - {{u.user_uuid_external}}
        </li>
      </ul>
      <div v-else>
         No members found.
      </div>
  </div>
</template>

<script>
export default {
  props: {
    members: {
      type: [Array],
      required: true,
    },
  },
}
</script>

<style scoped>

</style>