import { range, isNull, isNumber, includes } from 'lodash-es'

const ExamKinds = {
  Session: 'session',
  Container: 'container',
};

/*
 Slug	  Name	Questions	CompactName	Kind	    Children
 excsce	CSCE	100	      CSCE        container	"[""exnbdhe11"", ""exnbdhe12"", ""exnbdhe21"", ""exnbdhe22""]"	
 */
export const ExamColumns = {
  COL_EXAM_ID: 0,
  COL_EXAM_NAME: 1,
  COL_EXAM_QUESTIONS_TOTAL: 2,
  COL_EXAM_COMPACT_NAME: 3,
  COL_EXAM_KIND: 4,
  COL_EXAM_CHILDREN: 5,
};


export class Exam{
  /**
   * 
   * @param {Object} params
   * @param {Null|null} params.questionsTotal
   */
  constructor({ title, id, compactTitle, kind, questionsTotal, children }){
    this.title = title;
    this.id = id;
    this.compactTitle = compactTitle;
    this._questionsTotal = questionsTotal;
    this.kind = kind;
    /** @type {Array<String>} */
    this.children = children; 

    /** @type {null|Array<Exam>} when not those exams are not loaded; ORDER IS NOT SAME AS WIHTIN .children */
    this._childrenExams = null; // not loaded 
  }

  get questionsTotal(){
    if ( null === this._questionsTotal ){
      // We assume the children Exams are loaded... :S 
      return this._childrenExams.reduce((questionsTotal, exam)=>questionsTotal+exam.questionsTotal, 0);
    }
    else if ( isNumber(this._questionsTotal) ){
      return this._questionsTotal;
    }
    else{
      throw new Error(`Invalid value for Exam(${this.id}) field .questionsTotal: ${this._questionsTotal}`);
    }
  }

  /**
   * @returns {null|Array<Exam>}
   */
  get childrenExams(){
    return this._childrenExams;
  }

  /**
   * 
   * @param {Exam} childExam 
   * @returns {Exam}
   */
  addChild(childExam){
    this._ensureOurChild(childExam.id);
    this._ensureDontAlreadySet(childExam.id);
    if (null === this._childrenExams) {
      this._childrenExams = [];
    }
    this._childrenExams.push(childExam);
    return this;
  }



  _ensureOurChild(childId){
    const isOurs = includes(this.children, childId);
    if ( !isOurs ){
      throw new Error(`Exam.ent: Cannot add Child Exam(${childId}) because it does not belong to our list of children: [${this.children.join(',')}]`);
    }
  }

  _ensureDontAlreadySet(childId){
    if ( null === this._childrenExams) {
      return; // no children loaded at all
    }
    const loadedChildrenIds = this._childrenExams.map(childExam=>childExam.id);
    const isAlreadyIncluded = includes(loadedChildrenIds, childId);
    if ( isAlreadyIncluded ){
      throw new Error(`Exam.ent: Child exam with id(${childId}) is already loaded. Loaded exams: [${loadedChildrenIds.join(',')}]`);
    }
  }


  static many(n = 10){
    return range(0,n).map(Exam.dummy)
  }

  static dummy(n /*,i */){

    return new Exam({
      id: `ex${n}`,
      title: `Exam ${n}`,
    });
  }

  static fromDataRow(rowData){
    console.log('Exam.ent creating from rowData:', rowData);
    // TODO: column names are duplicated with ExamColumns enumeraitons; need fix!!
    const exam = new Exam({
      title: rowData['Name'],
      id: rowData['Slug'],
      compactTitle: rowData['CompactName'],
      questionsTotal: rowData['Questions'], // This would be Number (or null for 'container' exams)
      kind: isNull(rowData['Kind']) ?  ExamKinds.Session : rowData['Kind'],
      children: isNull(rowData['Children']) ?  [] : JSON.parse(rowData['Children']),
    });
    console.log(`Exam.ent:`, exam);
    return exam;
  }
}