import { createApp, inject, ref, reactive } from 'vue'


export class StudentGroupPresenter {

  constructor(sg, ctx){
    this._sg = sg;
    this._ctx = ctx;
    this._isSelected = false;

    // some default actions.
    this._actions = {
      primary: [
        // createPrimaryAction('Say Hello', this.doPrimaryAction_SayHello),
        // createPrimaryAction('Say Goodbye', this.doPrimaryAction_SayGoodbye),
      ],
      // secondary: [],
    }    
    if ( ctx.actionsPrimary ){
      if (!this._actions.primary){
        this._actions.primary = [];
      }
      this._actions.primary.push(...ctx.actionsPrimary);
    }

    if ( ctx.actionsSecondary ){
      if (!this._actions.secondary){
        this._actions.secondary = [];
      }
      this._actions.secondary.push(...ctx.actionsSecondary);
    }

  }

  get id(){
    return this._sg.uuid;
  }

  get isSelected(){
    return this._isSelected;
  }

  get item(){
    return this._sg;
  }

  get label(){
    const groupName =  this._sg.name;
    const tenant = this._sg.tenant_uuid;
    return `${tenant}::${groupName}`;
  }

  get actionsPrimary(){
    return this.actions.primary || [];
  }

  get actionsSecondary(){
    return this.actions.secondary || [];
  }

  get actions(){
    return this._actions;
  }

  doPrimaryAction_SayHello(presenter){
    // Can I mark it as selected? 
    alert('Say hello to ' + presenter.id);
    presenter._isSelected = true;
  }

  doPrimaryAction_SayGoodbye(presenter){
    alert(`Say Goodbye to '${presenter.label}' (${presenter.id})`)
  }

  


  static µFromStudentGroup(ctx){
    return (sg)=>{
      return new StudentGroupPresenter(sg, ctx);
    };
  }
  static µReactiveFromStudentGroup(ctx){
    return (sg)=>{
      return reactive(new StudentGroupPresenter(sg, ctx));
    };
  }
}



function createPrimaryAction(label, executorFn){
  const uuid = String(Date.now()) + String(Math.random());
  return {
    label,
    executorFn,
    uuid, // should this be depreacted?
    id: uuid,
  }
}