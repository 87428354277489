<template>
  <div class="co-student-list">
    <header class="co-student-list__header" v-if="showHeader">
      <span class="co-student-list__header__principal">
        {{title}}
      </span>
      <span class="co-student-list__header__actions-panel">
        <i @click="handleIconInfo" class="co-student-list__header__actions-panel__action fa fa-info-circle xfa-2x"/>
        <i @click="handleIconCircleDown" class="co-student-list__header__actions-panel__action fa fa-arrow-circle-o-down"/>
      </span>
    </header>

    <section class="co-student-list__body">
      <article v-for="presenter of items" v-bind:key="presenter.id" class="co-student-list__item">
        <span class="co-student-list__item__principal" :title="presenter.id">
          <span v-show="presenter.isSelected">SELECTED&nbsp;</span>
          {{presenter.label}}
        </span>
        <!-- <pre>[{{st}}]</pre> -->

        <span class="co-student-list__item__action-panel">
          <!-- <button class="co-student-list__item__action-panel__action btn btn-primary" @click="handlePrimary(presenter.item)">&lt;&lt; Add to group</button> -->
          
          <button v-for="actionDef of presenter.actionsPrimary"
                  :key="actionDef.id"
                  class="co-student-list__item__action-panel__action btn btn-primary" 
                  @click="actionDef.executorFn(presenter)"
                  >{{actionDef.label}}</button>

          <!-- <button class="co-student-list__item__action-panel__action btn btn-link" @click="handleLink(presenter.item)">Link</button> -->

          <i @click="handleItemJsonDisplay(presenter.item)" class="co-student-list__item__action-panel__action fa fa-info-circle"/>

        </span>
      </article>
    </section>
    
    <footer class="co-student-list__footer-empty-filler"></footer>


  </div>
</template>

<script>
export default {
  name: 'ItemList',
  // TODO: should I accept size from the parent somehow?
  computed: {
    showHeader() {
      return Boolean(this.title);
    }
  },
  data() {
    return {
      hello: 'World',
      // students: [
      //   // TODO: how do I load student? 
      // ],
    }
  }, 
  props: {
    title: {
      type: String,
      required: false,
      default: null,
      validator: (title)=>{
        return true; // all is good?
        // console.log(`Validating <ItemList title=??>`, title);
        // return false; // this will count as error, not sure about consequences
        // return `SImulating failure validating prop title`; // this does not return error, but is counted as Boolean(`...`) and success
      },
    },
    height:  {
      type: Number,
      default: null, // should we have automatic size
      validator: (v)=>{
        console.log(`Validating prop: <StudentList height=??>:`, v);
      },
    },

    items: {
      // type: Array,
      type: [Array, Object], // as in @see https://laracasts.com/discuss/channels/vue/how-to-pass-props-array-into-a-child-component-array-in-vue
      default: null, // we assume it's not
      validator: (itemPresenters, a,b,c)=>{
        // console.log(`itemPresenters:`, itemPresenters);
        // debugger;
        return true;
      },
    },
  },
  methods: {
    handlePrimary(student) {
      alert(`Handling primary for studnet: ${student.name}`);
    },
    handleLink(student) {
      alert(`Handling link for studnet: ${student.name}`);
    },
    handleItemJsonDisplay(item){
      const serialized = JSON.stringify(item, null,2);
      console.warn(`Item: `, serialized);
      alert(`Item: \n` + serialized);
    },
    handleIconInfo(){
      alert('Cliecked icon info');
    },
    handleIconCircleDown(){
      alert('Clicked icon circle down');
    }
  },

};
</script>

<style scoped>
.co-student-list{
  /* is this good */
  height: 100%; 
  /* background: blanchedalmond; */
}



.co-student-list__body{
  /* is this good? */
  overflow-y: scroll;
}

.co-student-list__item{
  display: flex;
    align-items: center;
    padding: 6px 0;
    padding-left: 8px;
}
.co-student-list__item__principal{
  flex-grow: 2;
}

.co-student-list__item__action-panel{

}

.co-student-list__item__action-panel__action{
  margin-right: 8px;
}

/* 
=======
HEADER
======= 
*/
.co-student-list__header { 
  background: #28b5f8;
    /* line-height: 3; */
    padding: 0 8px 0 8px;
    font-weight: bold;
    color: white;

    height: 60px;

    display: flex;
    align-items: center;
}

.co-student-list__header__principal{
  flex-grow: 1;
}

.co-student-list__header__actions-panel{
  
}
.co-student-list__header__actions-panel__action{
  margin: 0 12px;
  cursor: pointer;
}

/* 
=========
FOOTER
========= 
*/
.co-student-list__footer-empty-filler{
  flex-grow: 1;
}

</style>
