import { includes, keyBy, groupBy, minBy, maxBy } from "lodash-es";
import { Exam, Student } from "@/entities";
import { Attempt } from "@/entities/attempt.ent";
export class AttemptAggregator {
  /**
   *
   * @param {Object} params
   * @param {Exam} params.containerExam
   */
  constructor({ containerExam }) {
    if (!containerExam.children.length) {
      throw new Error(
        `AttemptAggregator: when creating instance of AttemptAggregator you must supply containerExam with non-empty children... (otherwise aggregation doesn't make sense; there's no children to aggregate across)`
      );
    }
    this._containerExam = containerExam;
    /** @type {Array<String>} */
    this._childIds = containerExam.children;
    /** @type {null|String} */
    this._currentStudent = null; // will be set when we add first attempt
                                 // currently coupled with this being StudentFullName, make sure you check properly when refactoring to StudentId
    this._attempts = [];
  }

  // /**
  //  *
  //  * @param {Array<Attempt>} attemptsCollection
  //  * @returns {self}
  //  */
  // sweepAttempts(attemptsCollection){ // which collection?
  //   throw new Error('Not implemented');
  //   for(let att of attemptsCollection){
  //     // first let's check if attempt is matching us... we collect for specific exams...
  //     // or for specific students...

  //   }
  //   return this; // need to return
  // },

  /**
   *
   * @param {Attempt} att
   * @returns {self}
   */
  addAttempt(att) {
    this._ensureIsOurChild(att.ExamId);
    this._ensureAggregatingSameUser(att.StudentFullName);
    
    this._attempts.push(att);
    return this;
  }

  toMapByChildId() {
    const map = {};
    for (const childId of this._childIds) {
      map[childId] = this;
    }
    return map;
    // return {
    //   'child1': this,
    //   'exam2': this,
    // };
  }

  // or toAttempt()?
  /**
   * 
   * @returns {null|Attempt} When nothing was aggregated, returns null
   */
  toCompoundAttempt() {

    if ( !this._attempts.length){
      console.warn(`AttemptAggregator: returning null (as there were nothing to aggregate) for ${this.toString()}`);
      return null;
    }
    // TODO: need to check that ._attempts[] is NOT empty
    // when it's empty we can't really calculate all those things..
    // and probably need to return null or smty
    // 

    // TODO: how to?
    //       should I simply try to assemble this shit, just
    //       like in the same manner I filled columns in the spreadsheet?
    //
    const { id } = this._containerExam;
    const uuid = btoa(Math.random()); // TODO: this needs to be replaced with something more descent... 
                                      //       or maybe even more systematic... and I also wonder if this should be deterministic? 
                                      //       otherwise we won't be able to save URL or anything for this guy.. 
                                      //       this should be maybe also taking user_id into account? 
                                      //       maybe it should be deterministic (or maybe this applies to the entire AttemptId)


    // TODO: How do I extract single item from each attempt? 
    const pageBy = (collection, key)=>{
      // const pageCollectionKeyed = keyBy(collection, key); // this assumes that all items are unique 
      const pageCollectionKeyed = groupBy(collection, key);
      // debugger;
      const pages =  Object.values(pageCollectionKeyed);
      return pages;
    };

    const latestAttemptsOnly = pageBy(this._attempts,'ExamId').map(page=>{
      // TODO: need to pick single latest one...
      /** @type {Array<Attempt>} */
      const attempts = page;
      // how 
      const latestAttempt = maxBy(attempts,'TimeSubmittedAt' /* thats Date so comparators should work */);
      // debugger;
      return latestAttempt;      
    });


    const questionsTotal = latestAttemptsOnly.reduce((_questionsTotal, att)=>{
        return _questionsTotal + att.QuestionsTotal;
    }, 0);

    const questionsAnsweredCorrectly = latestAttemptsOnly.reduce((_questionsAnsweredCorrectly, att)=>{
      return _questionsAnsweredCorrectly + att.QuestionsAnsweredCorrectly;
    }, 0);

    const questionsSkipped = latestAttemptsOnly.reduce((_questionsSkipped, att)=>{
      return _questionsSkipped + att.QuestionsSkipped;
    }, 0);

    console.assert(questionsTotal===this._containerExam.questionsTotal, 'Questions total must be equal');

    const timeDurationSeconds = pageBy(this._attempts,'ExamId').map(page=>{

      /** @type {Array<Attempt>} */
      const attempts = page;
      // attempts[0].TimeSubmittedAt
      // how to find latest attempt?

      const latestAttempt = maxBy(attempts,'TimeSubmittedAt' /* thats Date so comparators should work */);
      // debugger;
      return latestAttempt;
      // return findLatestAttempt(attempts);
    }).reduce((timeDurationSeconds, attempt)=>{
      // debugger;
      return timeDurationSeconds + attempt.TimeDurationSeconds;
    }, 0);

    console.log(`For student(${this._currentStudent}) and exam(${this._containerExam.title}) timeDurationSeconds:${timeDurationSeconds} seconds (aka ${timeDurationSeconds/60} mins)`);

    return new Attempt({
      StudentAttempt: `at_${id}_${uuid}`, // this is ID, but it's named weirdly
      ExamName: this._containerExam.title,
      ExamId: this._containerExam.id,
      TimeStartedAt: this._attempts.reduce(µSmallestProperty('TimeStartedAt'), this._attempts[0]['TimeStartedAt']),
      TimeSubmittedAt: this._attempts.reduce(µLargestProperty('TimeSubmittedAt'), this._attempts[0]['TimeSubmittedAt']),
      StudentFullName: this._currentStudent, // TODO: in future refactor to StudentID or smth
      
      QuestionsSkipped: questionsSkipped,
      // QuestionsAnsweredCorrectly: this._attempts.reduce(µSumProperty('QuestionsAnsweredCorrectly'), 0),
      QuestionsAnsweredCorrectly: questionsAnsweredCorrectly,

      // QuestionsTotal: this._attempts.reduce(µSumProperty('QuestionsTotal'), 0), // this is incorrect as it sums attempts, instead of child exams
      // QuestionsTotal: this._containerExam.childrenExams.reduce(µSumProperty('questionsTotal'), 0), // this probably would work
      QuestionsTotal: this._containerExam.questionsTotal, // this was incorrect, as it was summing up across all attmepts (not across latest attempts)
      // QuestionsTotal: questionsTotal,
      
      // I need to take only latest attempts for those specific exams?
      TimeDurationSeconds: timeDurationSeconds,
    });


    function µSumProperty(prop){
      return (acc, obj)=>acc + obj[prop];
    }

    function µLargestProperty(prop){
      return (largestCandidate,obj)=> {
        const val = obj[prop];
        return val > largestCandidate ? val : largestCandidate;
      }
    }

    function µSmallestProperty(prop){
      return (smallestCandidate, obj)=>{
        const val = obj[prop];
        return val < smallestCandidate ? val : smallestCandidate;
      }
    }

    
  }

  _ensureIsOurChild(examId) {
    if (includes(this._childIds, examId)) {
      return;
    }
    throw new Error(
      `AttemptAggregator: You supplied attempt of exam(${examId}) which is not a child of our ExamContainer which contains children: [${this._childIds.join(
        ","
      )}]`
    );

    /* All this beauty is left unused
    const uniqueChildIds = new Set(this._childIds);
    console.assert(1===uniqueChildIds.length, `ChildIDs can only contain single unique element, but it cointains more: ${this._childIds}`);

    const currentChildId = uniqueChildIds.values().next().value;

    if ( currentChildId !== examId){
      throw new Error(`AttempAggregator: we can only aggregate items of the same`)
    }
    */

    throw new Error(`Not implemented`);
  }

  _ensureAggregatingSameUser(studentFullName) {
    if (null === this._currentStudent) {
      this._currentStudent = studentFullName;
      return;
    }
    if (this._currentStudent !== studentFullName) {
      throw new Error(
        `AttemptAggregator: can only aggregate attempts which belong to the same student (${this._currentStudent}). You attempted to supply different student (${studentFullName})`
      );
    }
  }

  toString(){
    return `AttemptAggregator(ContainerExam.Id=${this._containerExam.id}, Attempt.Length=${this._attempts.length},CurrentStudent=${this._currentStudent})`;
  }

  static create(...args) {
    return new AttemptAggregator(...args);
  }
}
