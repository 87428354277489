export class StudentPresenter{
  constructor(student, ctx){
    this._student = student;
    this._ctx = ctx;
    this._actions = {
      primary: [
      //   { 
      //     label: 'Add to group',
      //     id: 'ID_add_to_group',
      //     executorFn: ()=>{
      //       alert('Doig things');
      //     } 
      //  }, // how to create an action? 
      ],
    };

    if ( ctx.actionsPrimary ){
      if (!this._actions.primary){
        this._actions.primary = [];
      }
      this._actions.primary.push(...ctx.actionsPrimary);
    }

    if ( ctx.actionsSecondary ){
      if (!this._actions.secondary){
        this._actions.secondary = [];
      }
      this._actions.secondary.push(...ctx.actionsSecondary);
    }    
  }

  get id(){
    return this._student.uuid;
  }

  get item(){
    return this._student;
  }

  get label(){
    const { first_name, last_name } = this._student;
    return `${first_name} ${last_name}`;
  }

  get actionsPrimary(){
    return this.actions.primary;
  }

  get actions(){
    return this._actions;
  }


  static createFromStudent(student, ctx = {}){
    return new StudentPresenter(student, ctx);
  }

}