<template>
  <div>
    <router-link :to="{ name: 'performance_s', params: { student_id: student.id } }">
      {{student.name}}
    </router-link>
  </div>
</template>

<script>
  export default {
    name: 'StudentCell',
    props: {
      student: {
        type: Object,
        default: ()=>{},
      },
      col: {
        type: Object,
        default: ()=>{},
      },
    },        
  }
</script>

<style scoped>

</style>