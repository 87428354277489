import { range, isString, isUndefined } from 'lodash-es'
import { makeSheetUrl } from "./utils";
import { verifyAll } from "../utils/verify";
import { toAlpha } from '../utils/utils'
export class BaseRepository {
  constructor({ gviz, sheetId, gid }) {
    verifyAll((verify) => {
      verify.notNil(gviz, "gviz");
      verify.notNil(sheetId, "sheetId");
      verify.notNil(gid, "gid");
    }, "when running contructor of a Repository");
    /** @type {google.visualization} */
    this._gviz = gviz;
    this._sheetId = sheetId;
    this._gid = gid;
  }

  /**
   * @returns {google.visualization}
   */
  get gviz() {
    return this._gviz;
  }

  get sheetId() {
    return this._sheetId;
  }

  get gid(){
    return this._gid;
  }

  get sheetUrl() {
    return makeSheetUrl({
      sheetId: this._sheetId,
      gid: this._gid,
      headers: 1,
    });
  }

  /**
   *
   * @param {String} queryString
   * @param {Object} opt
   * @param {String} opt.sheetId
   * @param {Number|String} opt.gid
   * @param {Number} opt.headers?
   * @return {google.visualization.DataTable}
   * @throws
   */
  async query(queryString, opt = {}) {
    /* opt must be empty object or destructring breaks */
    const asDataTable = Boolean(opt.asDataTable);
    const dataTableColumns = opt.dataTableColumns || [];
    const initiatedBy = (opt || {}).initiatedBy || "";
    return new Promise((resolve, reject) => {
      const { Query } = this.gviz;
      const sheetUrl = makeSheetUrl(opt) ? makeSheetUrl(opt) : this.sheetUrl;
      const query = new Query(sheetUrl, {});

      // this additional step may expand actual header column names into 'A' or 'E' or 'AE' column names
      const sql = this._transformQueryString({
        queryString, 
        initiatedBy, 
        asDataTable,
        dataTableColumns,
      });
      query.setQuery(sql);
      query.send((queryResponse) => {
        if (queryResponse.isError()) {
          return reject(
            new Error(
              `Error whilst making query [${sql}]: ${queryResponse.getDetailedMessage()}`
            )
          );
        }

        // const  = mapDataTableRows(queryResponse.getDataTable()).map(Student.fromDataRow);
        const dataTable = queryResponse.getDataTable();
        dataTable.mapRow = function mapDataDableRows(transformerFn) {
          const dt = this;
          // @see DataTable https://developers.google.com/chart/interactive/docs/reference#methods
          const columns = range(0, dt.getNumberOfColumns()).map((colIndex) => {
            return {
              colIndex,
              label: dt.getColumnLabel(colIndex),
              id: dt.getColumnId(colIndex), // i don't know what kind of value 'id' actually is...
              extractFromRow(rowIndex) {
                return dt.getValue(rowIndex, this.colIndex);
              },
            };
          });
          const rowToEntity = (rowIndex) => {
            let entity = {};
            for (let colDef of columns) {
              entity[colDef.colIndex] = entity[colDef.label] =
                colDef.extractFromRow(rowIndex);
            }
            return entity;
          };

          const allEntities = range(0, dt.getNumberOfRows()).map(rowToEntity);
          return allEntities.map(transformerFn);
        };
        resolve(dataTable);
      });
    });
  }

  /**
   *
   * @param {Array<Object>} allComponents
   */
  _compileQuery(allComponents) {
    const SEARCH_VALUE = 0;
    const COLUMN_NAME = 1;
    const COLUMN_LETTER = 2;
    const clauses = ["where", "and"];

    let sql = "";

    for (let i = 0; i < allComponents.length; i++) {
      const component = allComponents[i];
      if (isString(component)) {
        sql += component + " ";
        continue;
      } else if (Array.isArray(component)) {
        sql +=
          " " +
          component
            .filter((component) => !isUndefined(component[SEARCH_VALUE]))
            .map((component) => {
              return (
                whereAndAndAndAnd() +
                ` ${component[COLUMN_LETTER]} = '${escape(
                  component[SEARCH_VALUE]
                )}' `
              );
            })
            .join(" ");
      } else {
        throw new Error(
          `Logic error: uknown component type: ${typeof component} at index [${i}]`
        );
      }
    }

    return sql;

    function escape(target) {
      // TODO: need to figure out the escape logic...
      return target;
    }

    function whereAndAndAndAnd() {
      if (clauses.length > 1) {
        return clauses.shift();
      }
      return clauses[0];
    }
  }

  /**
   * 
   * @param {object} params
   * @param {String} params.queryString
   * @returns 
   */
  _transformQueryString({ queryString, initiatedBy, asDataTable, dataTableColumns }) {
    // TODO: simplest thing to do is to expand square brackets
    // dataTable = this.query(`select [*] where [StudentId] = '${ID}' `);

    // that this.constructor.name is a hack which surprisingly works.. (at least in dev)
    console.log(
      `Query String [${queryString}] from ${this.constructor.name} ${
        initiatedBy ? `initiated by ${initiatedBy}` : ""
      }`
    );
    
    // now let's transform 
    if ( dataTableColumns.length ){
      if ( asDataTable ){
        queryString = queryString.replace('select *', `select ${dataTableColumns.map(toAlpha).join(', ')} `);
        console.log(
          `Query String AFTER TRANSFORM [${queryString}] from ${this.constructor.name} ${
            initiatedBy ? `initiated by ${initiatedBy}` : ""
          }`
        );
      }
      else{
        console.warn(`dataTableColumns array of length=${dataTableColumns.length} was specified. But parameter asDataTable wasn't set.`);
      }
    }


    // console.log(stack());
    const compiled = queryString;
    return compiled;
  }
}

// function stack(){
//   const e = new Error();
//   return e.stack.toString();
// }
