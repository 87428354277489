<template>
  <authenticator :hide-sign-up="true">
    <template v-slot="{ user, signOut }">
      <header class="header">
        <div :class="headerContainerClassObject" xclass="container-fluid header-container">
          <div class="container">
            <!-- <div xxstyle="display: none;">isLocalhost() = {{isLocalhost}}</div> -->
            <div style="display: none;">isSuperAdmin({{user.attributes.email}}) = {{isSuperAdmin(user)}}</div>
            <div style="display: none;">Hello {{user.attributes.email}}</div>
            <nav class="navbar navbar-expand-sm navbar-dark xxbg-light" style="z-index: 10000; padding-top: 0">
              <div class="container-fluid">
                <!-- <a href="/#/" class="navbar-brand">Navbar</a> -->
                <!-- <span class="navbar-brand mb-0 h1">StudentRDH</span> -->
                <a href="#" class="navbar-brand">
                  <img
                    class="d-inline-block xalign-text-top" 
                    src="https://import.cdn.thinkific.com/172605/Lj2LPY7wTRWL0HdX0Ghr_Logo%20-StudentRDH.jpg" 
                    alt="StudentRDH Logo" 
                    width="64" 
                    height="64"
                    style="transform: translateY(5px)"
                    >
                    <div class="brand-logotype">
                      <div class="brand-logotype__container">
                        <span class="brand-logotype__title">StudentRDH</span>
                        <span class="brand-logotype__subtitle">Analytics</span>
                      </div>
                    </div>
                </a>
                <button 
                  class="navbar-toggler" 
                  type="button" 
                  data-bs-toggle="collapse" 
                  data-bs-target="#navbarNav"
                >
                  <span class="navbar-toggler-icon"></span>
                </button>
                <div class="navbar-collapse collapse" id="navbarNav">
                  <ul class="navbar-nav me-auto">
                    <li class="nav-item"><router-link class="nav-link" to="/dashboard">Dashboard</router-link></li>
                    <!-- <li class="nav-item"><router-link class="nav-link" to="/notes">Notes</router-link></li>
                    <li class="nav-item"><router-link class="nav-link" to="/notes/id123">Note 123</router-link></li> -->
                    <!-- <li class="nav-item"><router-link class="nav-link" to="/scores">Scores</router-link></li> -->
                    <li v-if="!isSuperAdmin(user)" class="nav-item"><router-link class="nav-link" to="/my/student-groups">Student Groups</router-link></li>
                    <li v-if="isSuperAdmin(user)"  class="nav-item dropdown">
                      <a 
                        href="#" 
                        class="nav-link dropdown-toggle" 
                        id="navbarDropdownMenuLink" 
                        data-bs-toggle="dropdown"
                      >
                        Database
                      </a>
                      <ul class="dropdown-menu">
                        <li><router-link to="/attempts" class="dropdown-item">Attempts</router-link></li>
                        <li><router-link to="/students" class="dropdown-item">Students</router-link></li>
                        <li><router-link to="/exams" class="dropdown-item">Exams</router-link></li>
                      </ul>
                    </li>
                    <li v-if="isSuperAdmin(user)" class="nav-item dropdown">
                      <a 
                        href="#" 
                        class="nav-link dropdown-toggle" 
                        id="navbarDropdownMenuLink2" 
                        data-bs-toggle="dropdown"
                      >
                        Lab
                      </a>
                      <ul class="dropdown-menu">
                        <li><router-link to="/lab/student-groups" class="dropdown-item">Student Groups</router-link></li>
                        <li><router-link to="/lab/students" class="dropdown-item">Students</router-link></li>
                        <li><router-link to="/lab/tenants" class="dropdown-item">Tenants</router-link></li>
                      </ul>
                    </li>
                    <li v-if="isSuperAdmin(user)" class="nav-item dropdown">
                      <a 
                        href="#" 
                        class="nav-link dropdown-toggle" 
                        id="navbarDropdownMenuLink3" 
                        data-bs-toggle="dropdown"
                      >
                        Manage
                      </a>
                      <ul class="dropdown-menu">
                        <li><router-link to="/manage/tenants" class="dropdown-item">Organizations</router-link></li>
                        <!-- <li><router-link to="/manage/student-groups" class="dropdown-item">Student Groups</router-link></li> -->
                      </ul>
                    </li>
                    <!-- <li class="nav-item"><router-link class="nav-link" to="/attempts">Attempts</router-link></li>
                    <li class="nav-item"><router-link class="nav-link" to="/students">Students</router-link></li>
                    <li class="nav-item"><router-link class="nav-link" to="/exams">Exams</router-link></li> -->
                    <!-- <li class="nav-item"><router-link class="nav-link disabled" to="/more" title="This is submenu where attempts live">More</router-link></li> -->
                    <!-- <li class="nav-item" no-need-for-this-with-me-auto-on-ul___style="flex-grow: 1">&nbsp;</li> -->
                    <!-- <li class="nav-item"><a href="#/logout" class="nav-link" @click.prevent.stop="signOut">Sign out</a></li> -->
                  </ul>
                  <!-- <span class="navbar-text" @click="signOut" style="cursor: pointer">Sign out</span> -->
                  <span class="navbar-text" @click="signOut" style="cursor: pointer">
                    Sign out 
                    <i v-if="isSuperAdmin(user)" style="margin-left: 4px; margin-right: 8px;" class="fa fa-id-badge" aria-hidden="true"></i>
                    <!-- <span v-if="isSuperAdmin(user)">
                      admin
                    </span> -->
                    <b>{{user.attributes.email}}</b>
                  </span>
                </div>
              </div>
            </nav>


          </div>
        </div>
        <div class="container header-breadcrumbs-container">
          <!-- <CjBreadcrumbs/> -->
        </div>
        <div class="header-seal"></div>
      </header>
      <div class="container-fluid" style="height: calc(100% - 140px); xxbackground-color: #efefef; overflow-y: scroll">
        <router-view></router-view>
      </div>
      <!-- <article id="debugBar">
        <ul>
          <li>Something <button class="btn btn-primary btn-sm">Release</button></li>
        </ul>
      </article> -->
    </template>
  </authenticator>
</template>

<script setup>
 import { Authenticator } from '@aws-amplify/ui-vue'
 import '@aws-amplify/ui-vue/styles.css'
//  import './css/debug-bar.css'
import './css/global.css'
//  setTimeout(()=>{
//    const debugBar = document.getElementById('debugBar');
//    alert(debugBar);

//  }, 3000);


</script>

<script>
/* eslint-disable vue/no-unused-components */

import { Auth } from 'aws-amplify'
// import HelloWorld from './components/HelloWorld.vue'
import CjBreadcrumbs from './components/CjBreadcrumbs.vue'

export default {
  name: 'App',
  async mounted () {
    Auth.currentSession().then(session=>console.log(`session:`, session));

    // interestingly enough that this seems to load things from localStorage 
    // even upon relogin... (the caUser will get refreshed later, but in this hook we still have stale user attributes )
    Auth.currentAuthenticatedUser().then(caUser=>console.log(`currentAuthenticatedUser:`, caUser));
    
  },
  computed: {
    isLocalhost() {
      return window.location.host.includes('localhost');
    },
    isCleverOrcaVT(){
      return window.location.host.includes('cleverorcavt.com');

    },
    isStudentRDH(){
      return window.location.host.includes('studentrdh.com');
    },
    headerContainerClassObject(){
      return {
        'container-fluid': true,
        'header-container': true,
        'header-container--studentrdh': this.isStudentRDH,
        'header-container--cleverorcavt': this.isCleverOrcaVT,
        'header-container--localhost': this.isLocalhost,
      };
    }
  },
  components: {
    // HelloWorld,
    CjBreadcrumbs,
  },
  data() {
    return {
      // TODO: this should be fed from the current user scope
      isAdmin: true,
      title: `Title page`,
    }
  },
  methods: {
    isSuperAdmin(user) {
      if ( user.attributes['custom:site_roles'] ){
        const site_roles_string = user.attributes['custom:site_roles'];
        const isSuper = site_roles_string.split(',').includes('SUPERADMIN');
        if ( isSuper) {
          console.log(`user.attributes`, user.attributes);
        }
        return isSuper;
      }
      return false;
      // return user.attributes['custom:site_roles'].split(',').includes('SUPERADMIN');
      // return user && user.attributes &&
    }
  },
}
</script>

<style>
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}
body{
  /* margin-top: 100px; */
}

controls {
  display: block;
}

.header{
  height: 140px;
  /* position: fixed; */
  left: 0;
  right: 0;
  top: 0;
  /* background-color: skyblue; */
  /* background-color: #86dcff; this one is too bright for white letters of the navbar */ 
  /* background-color: #04b5fb; we need to keep breadcrumbs within /Header/, hence bg should be on on another container */
  /* border-bottom: 1px solid gainsboro; */
}

.header > .header-container {
  background: gainsboro;
}

.header > .header-container--studentrdh {
  background: #04b5fb !important;
}

.header > .header-container--studentrdh {
  background: #fc6e23 !important;
}

.header > .header-container--localhost {
  background: rgb(119, 48, 167) !important;
}

.header > .header-seal {
  border-bottom: 1px solid gainsboro;
}



.btn-signout{
  float: right;
  position: fixed;
  top: 36px;
  right: 16px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  /* margin-top: 60px; */
}

ul.main-menu > li{
  display: inline-block;
  margin: 0;
  padding: 8px 16px;

}

.breadcrumb-wrapper{
  margin-top: 16px;
  margin-left: 16px;
}

.page{
  margin-top: 32px;
  margin-bottom: 48px;
}

/* .router-link-active, .router-link-exact-active{
  color: red;
} */

/* 
OVERRIDE DEFAULT BOOTSTRAP BUTTONS 
*/
#app .btn-primary {
  color: #fff;
  background-color: #28b5f8;
  border-color: #28b5f8;
}

#app .btn-primary:hover {
  color: #fff;
  background-color: hsl(199deg 94% 44%);
  border-color: hsl(199deg 94% 44%);
}


/* -----------------------------------
   WIDGET
   -----------------------------------*/
.widget-small{
  margin-top: 16px;
  width: 100%;
  height: 300px;
  background: white;
  border: 1px solid gainsboro;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
}

.widget-small.widget-small--full-height{
  height: 100%;
}

.widget-small.widget-small--tight{
  margin: 0;
}

.widget-small.widget-small--light{
  background-color: white;
}

.widget-small.widget-small--round{
  border-radius: 12px;
}

.widget-small__percentage{
  font-size: 36px;
  /* font-weight: bold; */
}

.widget-small__title{
  font-size: 24px;
}

.widget-small__hint{
  margin-top: 4px;
  font-size: 14px;
  color: gainsboro
}

.brand-logotype{
  display: inline-block;
  margin-left: 10px;
}
.brand-logotype__container{
  display: flex;
  flex-direction: column;
}
.brand-logotype__title{
  line-height: 1;
}
.brand-logotype__subtitle{
  font-size: 12px;
}

/**************************************
  Google Charts: Style override 
***************************************/
.google-visualization-table-table{
  font-family: Avenir, Arial, Helvetica, sans-serif !important;
}
.google-visualization-table-table .google-visualization-table-tr-head{
  /* background-color: #0dcaf0; */
  background-color: #61d6ff; /* from student rdh */
}
.google-visualization-table-th.gradient{
  background-image: none !important;
}

/****************************************
  Bootstrap global overrides
*****************************************/
.nav-link{
  color: #04b5fb;
}
.breadcrumb .breadcrumb-item a {
  color: white;
}

.btn-brand{
  color: white;
  background-color: orange;
  border-color: orange;
}

.btn-outline-primary{
    color: #04b5fb;
    border-color: #04b5fb;
}

.btn-check:checked+.btn-outline-primary{
    color: #fff;
    background-color: #04b5fb;
    border-color: #04b5fb;
}

.text-primary{
  color: #04b5fb !important;
}


/****************************************
  Amplify UI
*****************************************/
[data-amplify-authenticator]{
  margin-top: 100px;
  margin-top: 30vh;
}


/****************************************
  //Tenant list//
*****************************************/
.tenant-list{
  /* overflow-y: scroll; */
}
.tenant-list .scroll{
  overflow-y: scroll;
  height: calc(100% - 50px);
}
.tenant-list > header{
  height: 50px;
  overflow: hidden;
  background: skyblue;
  color: white;
  display: flex;
  align-items: center;
  padding: 0 16px;
  
}
.tenant-list header > span {
  font-size: 24px;
  font-weight: bold;
}

</style>
