import { range } from 'lodash-es'
export class Student {
  constructor({ fullName, id, email }){
    this.fullName = fullName;
    this.id = id;
    this.email = email;
  }


  get name(){
    return this.fullName;
  }


  static many(n = 10){
    return range(0,n).map(Student.dummy)
  }

  static dummy(n /*,i */){
    if ( undefined === n ) {
      n = Math.floor(Math.random()* 10000);
    }
    return new Student({
      id: `st${n}`,
      fullName: `Student ${n}`,
    });
  }

  /**
   * 
   * @param {*} rowData
   * @returns {Student}
   */
  static fromDataRow(rowData){
    // console.log('Row data', rowData);
    return new Student({
      fullName: rowData.fullName,
      id: rowData.id,
      email: rowData.email,
    })
    // return this.dummy();
  }

  /*
  {
      "id": "azzsu1rn:adell_wolff",
      "firstName": "Adell",
      "lastName": "Wolff",
      "fullName": "Adell Wolff",
      "slug": "adell_wolff",
      "domain": "yahoo.co.jp",
      "email": "adell.wolff@yahoo.co.jp",
      "idPrefix": "azzsu1rn"
  }
  */
}