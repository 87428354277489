import { Exam, Student } from '../entities'
import { BaseRepository } from './base-repository'
import { makeSheetUrl, mapDataTableRows } from './utils'
import { toAlpha } from '@/utils/utils'

export class StudentDataImpl extends BaseRepository {

  constructor(params){
    super(params);
  }

  /**
   * @param {String|null} student_id
   */
  async findStudent(student_id, qOpt = {}){
    // const sql = `select * where 'id' = '${student_id}'`;
    const sql = `select * where A = '${student_id}'`;
    const dataTable = await this.query(sql, { 
      sheetId: this.sheetId,
      // gid: '1323244833', // 'Students' sheet
      gid: this.gid, // default one which points to 'Students' sheet in this repo
      headers: 1,
      ...qOpt,
    });

    const students = dataTable.mapRow(Student.fromDataRow);
    if ( students.length == 0 ){
      return null;
    }
    if ( students.length > 1 ){
      throw new Error(`Logic error: Querying for student with id(${student_id}) using query [${sql}] returned multiple results(${students.length}) instead of single.`);
    }
    return students[0];
  }

  /**
   * @returns {Promise<Student[]>}
   * @deprecated need to refactor using super.query()
   */
  async listStudents(){
    return new Promise((resolve, reject)=>{
      const { Query, /* QueryResponse, QueryWrapper, CustomQuery, DataTable, Table */ } = this.gviz;
      let query = new Query(makeSheetUrl({
        sheetId: this.sheetId,
        gid: '1323244833',
        headers: 1,
      }), {
        
      })
      query.setQuery('select A,B,C,D,E,F,G,H');
      query.send(queryResponse=>{
        if ( queryResponse.isError() ){
          return reject(new Error(queryResponse.getDetailedMessage()))
        }
        // how is the value returned? how do I feed the entity? 
        const students = mapDataTableRows(queryResponse.getDataTable()).map(Student.fromDataRow)
        resolve(students)
      })
      // gid=1323244833
      // how 
    })


    // return Student.many();
  }
  
  async findAll({ ord = '' } = {}, qOpt = {}){
    // const STARTED_AT = toAlpha(AttemptColumns.COL_TIME_STARTED_AT);
    // const sql = !ord ? `select *` : `select * order by ${STARTED_AT} ${ord}`;
    const sql = `select *`;
    return mapDataTableRows(await this.query(sql, qOpt)).map(Student.fromDataRow);
  }  


  /**
   * @returns {Promise<Exam[]>}
   */
  // eslint-disable-next-line
  async listExams(opt = {}, qOpt = {}){
    const sql = `select *`;
    const dataTable = await this.query(sql, { 
      sheetId: this.sheetId,
      gid: '433448846', // 'Exams' sheet
      headers: 1,
      ...qOpt,
    });

    return dataTable.mapRow(Exam.fromDataRow)
  }


  /**
   * 
   * @param {*} params 
   * @returns {Promise<StudentDataImpl>}
   */
  static async createAsync (params){
    return new StudentDataImpl(params);
  }

}


// export const StudentData = StudentDataImpl.create();


