import { /* keyBy,*/ isUndefined, isString, get } from 'lodash-es'
// import { categoryMetaDefinitionsIndexedById } from './lib/category-metadata'

// TODO: rename to CategoryMetric (and retrievers too)
export class CategoryMetric {
  constructor({ id, answeredCorrectly, answeredIncorrectly, skipped, title }){
    this.id = id;
    this.answeredCorrectly = answeredCorrectly;
    this.answeredIncorrectly = answeredIncorrectly;
    this.skipped = skipped;
    this._title = title; // can be undefined
    this._categories = null; // when not set (null), state of categories unknown
  }
  get title(){
    return this._title || 'No Title';
  }

  get total(){
    return this.answeredCorrectly + this.answeredIncorrectly + this.skipped;
  }
  get scorePercent(){
    const score = this.answeredCorrectly / this.total * 100;
    return Number(score.toFixed(1));
  }

  get scorePercentInt(){
    return Math.floor(this.scorePercent);
  }

  /**
   * 
   * @param {String} serialized serialized string in form:
   * #param {String|null} columnName optional name of columns 'Col_ABC' which can be used to extract Id or validate id
   *   'ABC : [0,1,2]'
   *   'ABC : [0,1,2]'
   */
  static fromSerialized(serialized, { columnName = null,  categoryMetaDefinitionsIndexedById }){

    if ( isUndefined(serialized) ){
      debugger;
    }

    if ( !columnName ){
      debugger;
    }
    
    const catIdFromColumnName = columnName ?  columnName.replace('Cat_','') : undefined;

    if ( !isString(serialized) ){
      throw new Error(`Category#fromSerialized(${serialized}, ${columnName}) first argument is NOT a string`);
    }
    // first we need to extract id... 
    // after that we need to parse array
    // then we need to validate it's length
    // and we need to pad the array
    const { catId, metricsArray } = CategoryMetric._parseSCMS(serialized); // parseSerializedCategoryMetricsString

    // Check that we have at least ONE way to identify category Id
    if ( undefined === catId && undefined === catIdFromColumnName ){
      throw new Error(
        `Cannot determine name of the column. ` 
      + ` It was not present neither in the SMCS/SerializedCategoryMetricsString (${serialized})` 
      + ` neither columnName(${columnName}) was specified `
      );
    }

    // Check that if we have both ways to identify category Id, they actually match
    if ( catId && catIdFromColumnName ){
      if ( catId !== catIdFromColumnName ){
        throw new Error(
          `Mismatch of category Ids: ` 
          +` supplied as columnName(${columnName}) does not match `
          +` value extracted from SMSC/SerializedCategoryMetricsString(${catId})`
        );
      }
    }
    
    // Now get at least one 
    const id = catId || catIdFromColumnName;


    // All array elements are optional, so we have to do this dance with isUndefined
    // *   'ABC : [0,1,2]'
    // *   'ABC : [0,1,2]'    
    // *   'ABC : [0,1]'   // no skipped
    // *   'ABC : [0]'     // no incorrect, no skipped  (techincally this is an empty category)
    // *   'ABC : []'      // no correct, no incorrect, no skipped (well techincally this is an empty category too)
    return new CategoryMetric({
      id,
      // id: catId || catIdFromColumnName,
      answeredCorrectly: isUndefined(metricsArray[0]) ? 0 : metricsArray[0],
      answeredIncorrectly: isUndefined(metricsArray[1]) ? 0 : metricsArray[1],
      skipped: isUndefined(metricsArray[2]) ? 0 : metricsArray[2],
      // title: categoryMetaDefinitionsIndexedById[id], // can be undefined...then we won't have title
      title: get(categoryMetaDefinitionsIndexedById, `${id}.title`), // can be undefined...then we won't have title
    })
    
  }
  
  static _parseSCMS(scms){
    // console.warn(`_parseSCMS(${scms})`);
    // KEEP IN MIND THAT GROUP WITH ID OF THE CATEGORY IS OPTIONAL
    // ALSO, NOTE THAT ARRAY CAN BE EMPTY '[]'
    // All array elements are optional, so we have to do this dance with isUndefined
    // *   'ABC : [0,1,2]'
    // *   'ABC : [0,1,2]'    
    // *   'ABC : [0,1]'   // no skipped
    // *   'ABC : [0]'     // no incorrect, no skipped  (techincally this is an empty category)
    // *   'ABC : []'      // no correct, no incorrect, no skipped (well techincally this is an empty category too)
    // *   '[0,1,2]'
    // *   '[0,1,2]'    
    // *   '[0,1]'   // no skipped
    // *   '[0]'     // no incorrect, no skipped  (techincally this is an empty category)
    // *   '[]'      // no correct, no incorrect, no skipped (well techincally this is an empty category too)

    // @see https://regex101.com/r/iauSXf/2      or DELETE via https://<DELETETHISPREFIX>regex101.com/delete/ALkTmXvyumGBpS7Kbm99lFhD
    const regex = /^\s*((\S+)\s*[:]\s*)?(\[[^\]]*\])\s*$/;
    const GROUP_CAT_ID = 2;
    const GROUP_ARRAY = 3;
    const matches = scms.match(regex);
    if ( null === matches ) {
        throw new Error(`Not matches found via regex(${regex}) for SCMS string '${smcs}'`);
    }
    return {
      catId: matches[GROUP_CAT_ID],           // can be <undefined>
      metricsArray: JSON.parse(matches[GROUP_ARRAY]),
    };

  }
}