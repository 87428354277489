<template>
  <div v-if="isShown" class="co-floatie">
    <header class="co-floatie__header">

    </header>
    <section class="co-floatie__body">
      <h2>Hello from floatiepopp</h2>

    </section>
    <footer class="co-floatie__footer">

    </footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // isShown: value
    }
  },
  props: {
    isShown: {
      type: Boolean,
      required: true,
    },
  },
  
}
</script>

<style scoped>
.co-floatie{
  width: 200px;
  height: 200px;
  position: absolute;
  bottom: 0;
  left: 0;

  background: antiquewhite;
  /* padding: 16px; */
  border-radius: 10px;
  /* visibility: hidden;   */
  

}

</style>