<template>
  <main class="container page">
    <!-- <HelloGraphQL msg="Bobson is my name"></HelloGraphQL> -->
    <student-list prefix="studnet: "></student-list>

    <button @click="changeComponent" class="btn btn-info">Component</button>
    <component :is="currentComponent"/>
  </main>
</template>

<script>
/* eslint-disable vue/no-unused-components */

import HelloGraphQL from '../components/lab/HelloGraphQL.vue'
import StudentList from '../components/student/StudentList.vue'


export default {
  components: {
    // HelloWorld,
    StudentList,
    HelloGraphQL
  },
  data() {
    return {
      currentComponent: null,
    }
  },
  methods: {
    changeComponent() {
      alert(`Changing component`);
      // this.currentComponent = 'hello';
      this.currentComponent = HelloGraphQL;
    }
  },  
}
</script>

<style scoped>

</style>