<template>
  <div class="cj-student-list">
    <controls class="cj-student-list__controls">
      <span class="text-primary">
        Highlight exams <b>below</b> &quot;passing grade level&quot; of &nbsp;&nbsp;
      </span>
      <div class="btn-group" role="group" aria-label="Basic radio toggle button group">


        <input v-model="highlightLessThan" value="50" type="radio" class="btn-check" name="btnradio" id="btnradio5" autocomplete="off">
        <label class="btn btn-outline-primary" for="btnradio5">&lt;50%</label>

        <input v-model="highlightLessThan" value="70" type="radio" class="btn-check" name="btnradio" id="btnradio3" autocomplete="off">
        <label class="btn btn-outline-primary" for="btnradio3">&lt;70%</label>

        <input v-model="highlightLessThan" value="80" type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off">
        <label class="btn btn-outline-primary" for="btnradio2">&lt;80%</label>

        <input v-model="highlightLessThan" value="90" type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off">
        <label class="btn btn-outline-primary" for="btnradio1">&lt;90%</label>

        <input v-model="highlightLessThan" value="" type="radio" class="btn-check" name="btnradio" id="btnradio4" autocomplete="off">
        <label class="btn btn-outline-primary" for="btnradio4">do not higlight</label>

        <!-- TODO: Need to finish this one
        <input v-model="highlightLessThan" value="gte90" type="radio" class="btn-check" name="btnradio" id="btnradio6" autocomplete="off">
        <label class="btn btn-outline-primary" for="btnradio6">above 90%</label> 
        -->

      </div>
      <div style="flex-grow: 2;"></div>
      <div>
        <div class="input-group">
          <div class="input-group-prepend">
            <label class="input-group-text" for="inputGroupSelect01">Summary of</label>
          </div>
          <select class="custom-select" id="inputGroupSelect01">
            <option selected value="1">Last Attempt</option>
            <option value="2">Best of all</option>
            <option value="3">Best of last 3</option>
          </select>
        </div>
      </div>
    </controls>
    <div>
      <!-- <h3>Simple switch {{isSwitchedOn}}</h3> -->
      <label>
        <!-- Highlight exams below passing level -->
        <!-- <input type="number" v-model="highlightLessThan"> -->
        <!-- <input type="checkbox" v-model="isSwitchedOn"> -->
      </label>
    </div>

     <table v-if="columns" class="table table-light table-hover student-table">
       <thead class="table-light">
        <tr>
          <th v-for="col in columns" v-bind:key="col.id" data-id="col.id"
              style="white-space: nowrap;"
          >{{col.label}}</th>
        </tr>
       </thead>

       <tbody>
        <tr v-for="st in students" v-bind:key="st.id">
          <td v-for="col in columns" v-bind:key="col.id">
             <component :is="col.cellComponent" 
                        :student="st" 
                        data-col-comment="Note: col.id is an exam.id (for ExamCell2 components, not for StudentCell)"
                        :col="col" 
                        xxxhighlight="isSwitchedOn" 
                        :highlight-function="uMakeHighlight({ lt: highlightLessThanAsNumber })"
                        ></component>
          </td>
        </tr>

<!-- 
        <tr v-for="st of students" v-bind:key="st.id">
          <td><router-link :to="'/student/' + st.id + '/score'">{{st.name}}</router-link></td>
          <td>{{st.age}}</td>
          <td>
            <router-link 
              :to="'/student/' + st.id + '/score'"
              custom
              v-slot="{ navigate }"
            >
                <button class="btn btn-sm btn-success" @click="navigate">Open</button>
            </router-link>
          </td>
          <td>
            <button 
              @click="selectStudent(st)" 
              class="btn btn-primary">
              Details
            </button>
          </td>
        </tr> 
-->
      </tbody>
      </table>
      <div v-else>
        <h2>Loading data...</h2>
      </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { keyBy, isNumber } from 'lodash-es'
import { areAllButSingleValuesNil } from '../../utils/utils'
import { StudentData, AttemptRepository } from '../../services'
import StudentCell  from './StudentList/StudentCell.vue'
import ActionCell from './StudentList/ActionCell.vue'
import ExamCell from './StudentList/ExamCell2.vue'
export default {
  name: 'StudentList',
  props: {
    prefix : String,
  },
  
  components: {
    StudentCell,
    ActionCell,
    ExamCell,
  },

  created(){
    this.loadStudents()
    // this.loadLastAttempts();
    this.loadExams().then(exams=>{

      this.columnBody = exams.map(toColumnDefinition);

      function toColumnDefinition(exam){
        return {
          label: exam.compactTitle,
          id: exam.id,
          cellComponent: 'exam-cell',

        };
      }
    })
  },


  computed: {
    columns() {
      if ( !this.columnBody.length ) return null; // nothing before body is loaded.
      return [...this.columnHead, ...this.columnBody, ...this.columnTail];
    },
    highlightLessThanAsNumber(){
      return isNumber(this.highlightLessThan) ? this.highlightLessThan : parseInt(this.highlightLessThan, 10);
    }
  },

  data() {
    return {
      students: [],
      exams: [],
      lastAttempts: [],
      lastAttemptsByStudent:[],
      columnHead: [
          { label: 'Student', id : 'student', cellComponent: 'student-cell' },
      ],
      columnBody: [
          // { label: 'Exam 1', id : 'exam1', cellComponent: 'exam-cell' },
          // { label: 'Exam 2', id : 'exam2', cellComponent: 'exam-cell' },
          // { label: 'Exam 3', id : 'exam3', cellComponent: 'exam-cell' },
          // { label: 'Score', id : 'score', cellComponent: 'exam-cell' },
      ],
      columnTail: [
          // { label: 'Actions', id : 'actions', cellComponent: 'action-cell' },
      ],
      // isSwitchedOn: false,
      highlightLessThan: '', // Number or Numeric string are both fine, as there's computed one which parses
    }
  },

  methods: {
    selectStudent(st) {
      alert(`Selected student: ${st.name}`);
    },

    async loadExams(){
      return this.exams = await StudentData.listExams({ initiatedBy: 'StudentList#loadExams()'});
    },

    async loadStudents(){
      this.students = await StudentData.listStudents({ initiatedBy: 'StudentList#loadStudents()'});
    },

    async loadLastAttempts(){
      const allAttempts = await AttemptRepository.findAll({ ord: 'DESC' }, { initiatedBy: 'StudentList#loadLastAttempts()'});
      
      this.lastAttempts = takeFirst(allAttempts, function attemptsEqual(a1, a2){
        return a1.StudentFullName === a2.StudentFullName;
      })

      this.lastAttemptsByStudent = keyBy(this.lastAttempts, 'StudentFullName');
      console.log('lastAttemptsByStudent:', this.lastAttemptsByStudent);
    },

    uMakeHighlight({ lt, lte, gte }){
      // if ( Math.random() < 0.1 ) console.log(`uMakeHighlight(lt:${typeof lt}=${lt}, lte:${typeof lte} = ${lte}, gte:${typeof gte} = ${gte})`);
      if ( !areAllButSingleValuesNil(lt, lte, gte) ){
        throw new Error(`Only single 'operation paramter' (eg. lt|lte|gte) is allowed`);
      }
      return (attempt)=>{
        if ( isNumber(lt) ){
          return attempt.ScorePercent < lt;
        }
        else if ( isNumber(lte) ){
          return attempt.ScorePercent <= lte;
        }
        else if ( isNumber(gte) ){
          return attempt.ScorePercent >= gte;
        }
        else {
          return false; // do not highlight
          throw new Error(`LogicError: Cannot detect operation for uMakeHighlight`);
        }
      };
    }
  },

}

/** 
 * @param {Array}
 * @returns {Array} 
 */
function takeFirst(ar, eq){
  let firsts = [];

  for(let el of ar){
    if ( alreadyIncludes(el) ) continue;
    firsts.push(el);
  }

  return firsts;

  function alreadyIncludes(el){
    for(const f of firsts){
      if ( eq(f, el) ) return true;
    }
    return false;
  }
}
</script>

<style scoped>
.cj-student-list{

}

.cj-student-list__controls{
  display: flex;
}
.student-table{
  min-width: 400px;
  border: 1px solid gainsboro;
}

</style>

