import gql from 'graphql-tag'
export const MUTATION__CREATE_INVITATION = gql`mutation CreateInvitation($inputInvitation: InputInvitation) {
  newInvitation: createInvitation(inputInvitation: $inputInvitation) {
    uuid
    status
    destination_email
    tenant_uuid
    created_at
    updated_at
    synced_at
    last_sent_at
    sent_history
    user_uuid
    user_uuid_external
    invitation_message_text
    invitation_message_html
  }
}`;
// export const MUTATION__CREATE_INVITATION = gql`mutation CreateInvitation($name: String) {
//   newTenant: createTenantFromName(name: $name) {
//     uuid
//     title
//     is_active
//     kind
//   }
// }`;
