import { createRouter, createWebHashHistory } from 'vue-router'

import StudentDashboard from './views/StudentDashboard.vue'
import NotFound from './views/NotFound.vue'
import StudentExamPerformance from './views/student/exam/Performance_SE.vue'
import StudentOverallPerformance from './views/student/Performance_S.vue'
import AttemptList from './components/data/AttemptList.vue'
import ExamList from './components/data/ExamList.vue'
import StudentList from './components/data/StudentList.vue'
import AttemptDetail from './components/data/AttemptDetail.vue'

// lab
import StudentNotes from './components/lab/StudentNotes.vue'
import ApolloGraphQL from './components/lab/ApolloGraphQL.vue'
import StudentGroupsList from './components/lab/StudentGroupsList.vue'
import StudentGroupsListContentsOf from './components/lab/StudentGroupsListContentsOf.vue'

// MY
import MyStudentGroupsView from './features/my-cabinet/MyStudentGroupsView.vue'
import MyStudentGroupsDetailsView from './features/my-cabinet/MyStudentGroupsDetailsView.vue'

// MANAGE
import ManageTenants from './features/tenant-management/ManageTenants.vue'


const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    { path: '/', component: StudentDashboard, name: 'HOME' },
    { path: '/dashboard', component: StudentDashboard, name: 'DASHBOARD' },
    // { path: '/student/:student_id/score', component: StudentExamPerformance },
    { 
      name: 'performance_se',
      path: '/student/:student_id/exam/:exam_id/performance', 
      component: StudentExamPerformance,
      meta: {
        crumbs: makeCrumbsForStudentExamPerformance,
        title: (route)=>`StudentRDH Analytics - Individual Performance`,
        // title: (route)=>`StudentRDH Analytics - ${route.fullPath}`,
        name: 'Bob',
        age: 33,
      }
    },
    {
      name: 'performance_s',
      path: '/student/:student_id/overall-performance',
      component: StudentOverallPerformance,
    },
    // { 
    //   name: 'performance_s',
    //   path: '/student/:student_id/overall-performance', 
    //   component: NotFound,
    //   // meta: {
    //   //   crumbs: makeCrumbsForStudentExamPerformance,
    //   //   title: (route)=>`StudentRDH Analytics - Student Performance`,
    //   //   // title: (route)=>`StudentRDH Analytics - ${route.fullPath}`,
    //   //   name: 'Bob',
    //   //   age: 33,
    //   // }
    // },

    { path: '/students', component: StudentList, name: 'STUDENTS' },
    { path: '/exams', component: ExamList, name: 'EXAMS' },
    { path: '/attempts', component: AttemptList, name: 'ATTEMPTS' },
    { path: '/attempt/:attempt_id/exam/:exam_id/attempt-detail', component: AttemptDetail },

    // ------------
    // MY
    // ------------
    { path: '/my/student-groups', component: MyStudentGroupsView, name: 'MY__STUDENT_GROUPS'},
    { path: '/my/student-groups/:group_id', component: MyStudentGroupsDetailsView, name: 'MY__STUDENT_GROUPS_DETAILS'},


    // ------------
    // LAB
    // ------------
    { path: '/lab/graphql', component: ApolloGraphQL, name: 'LAB_GRAPHQL'},
    { path: '/notes', component: StudentNotes },
    { path: '/notes/:note_id(id\\d+)', component: StudentNotes },    
    { path: '/lab/student-groups', component: StudentGroupsList, name: 'LAB_STUDENT__GROUPS_LIST'},
    { path: '/lab/student-groups/:group_uuid', component: StudentGroupsListContentsOf, name: 'LAB_STUDENT__GROUPS_LIST_CONTENTS_OF'},

    // ------------
    // MANAGE
    // ------------
    { path: '/manage/student-groups', component: StudentGroupsListContentsOf, name: 'LAB_STUDENT__GROUPS_LIST_CONTENTS_OF'},
    { path: '/manage/student-groups/:group_id', component: StudentGroupsListContentsOf, name: 'STUDENT_GROUP_MANAGE_SINGLE'},
    { path: '/manage/tenants', component: ManageTenants },
    
    // ------------
    // CATCHALL
    // ------------
    { path: '/:pathMatch(.*)', component: NotFound },
    
  ],
  linkActiveClass: 'cj-active',  // need to be careful if Bootstrap tabs will conflict with this broad match
  linkExactActiveClass: 'active',
})

router.beforeEach((to, from, next)=>{
  if ( 'function' == typeof to.meta.title ) document.title = to.meta.title(to);

  next();
})


function makeCrumbsForStudentExamPerformance(route){
  console.log(`makeCrumbsForStudentExamPerformance: $route`, route);
  // if ( route.matched && route.matched[0] ){
  //   const view = route.matched[0];
  //   return [
  //     { name: view.components.default.computed.exam_id() }, // this doesn't run as component is not ready or mounted or smth like that
  //   ];
  // }  

  return [
    { name: 'Students', to: '#/dashboard' },
    { name: route.params.student_id, xxto: '#/to-somewhere2' },
    { name: route.params.exam_id, xxto: '#/to-somewhere3' },
    { name: 'Individual Performance' },
  ];
  // return [
  //   { name: 'Students', to: '#/dashboard' },
  //   { name: 'Mary Poppins', xxto: '#/to-somewhere2' },
  //   { name: 'Exam 123', xxto: '#/to-somewhere3' },
  //   { name: 'Individual Performance' },
  // ];
}


export default router;