<template>
  <div>
    <button class="btn btn-primary btn-sm" @click="doDetails">Details</button>
  </div>
</template>

<script>
  export default {
    name: 'ActionCell',
    props: {
      student: {
        type: Object,
        default: ()=>{},
      },
      col: Object,
    },   
    methods: {
      doDetails(){
        alert('doDetails')
      }
    },
  }
</script>

<style scoped>

</style>