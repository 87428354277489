<template>
  <div class="container page">
    <h4>The page you're looking for could not be found</h4>
    <pre style="background: orange; display: inline-block;">/{{ $route.params.pathMatch }}</pre>
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style scoped>

</style>