<template>
  <main class="container co-stack" style="height: 100%">
    <!-- <h3>this is group ({{groupId}}) details</h3> -->
    <h2 :title="groupId">Group members</h2>
    <!-- <controls>
      <button class="btn btn-primary">Add</button>
    </controls> -->
    <div paper>
      <section v-if="students">
          <span v-if="students.length">
            <ul>
              <li v-for="st in students" :key="st.uuid">
                <router-link :to="`/student/${st.uuid}/overall-performance`">
                  {{st.first_name}} {{st.last_name}} ({{st.email}})
                </router-link>
              </li>
            </ul>
          </span>
          <span v-else>
            <h4 style="margin-top: 32px">No students in this group.</h4>
            <p>Contact administrator to assign students to this gruop</p>
          </span>
      </section>
      <section v-else>
        <h3>Loading students...</h3>
      </section>
    </div>
  </main>

</template>

<script>
import { LIST_ITEMS_OF_STUDENT_GROUP } from '../../gql/list-items-of-student-group.gql'
export default {

  // apollo: {
  //   students: {
  //     query: LIST_ITEMS_OF_STUDENT_GROUP,
  //     variables: {
  //       groupUuid: this.groupId,
  //     },
  //     update: function(data){
  //       debugger;
  //       return data.students.map(st=>({...st}));
  //     }
      
  //   },
  // },

  computed: {
    groupId() {
      debugger;
      return this.$route.params.group_id;
    }
  },
  async created () {
    const groupId = this.$route.params.group_id;
    debugger;
    this.students = await this.listItemsOfStudentGroup(groupId);
  },
  data() {
    return {
      students: null,
    }
  },
  methods: {
    async listItemsOfStudentGroup(groupId) {
        const { error, data } = await this.$apollo.query({
          query: LIST_ITEMS_OF_STUDENT_GROUP,
          variables: {
            groupUuid: groupId,
          },
        });
        if ( error ){
          console.error(`error queerying LIST_ITEMS_OF_STUDENT_GROUP`, erorr);
          throw error; // is this the way?
        }
        // making copies? why? 
        return data.students.map(st=>({...st})); 
      },
    }


  
}
</script>

<style lang="css" scoped>

</style>