// eslint-disable-next-line
import { StudentDataImpl } from './student-data'
// eslint-disable-next-line
import { AttemptRepository as AttemptRepositoryImpl } from './attempt-repo'
// eslint-disable-next-line
import { ExamRepository as ExamRepositoryImpl } from './exam-repository'
// eslint-disable-next-line
import { AttemptRegistry as AttemptRegistryClass } from './attempt-registry'

/** @type {StudentDataImpl} */
let StudentData = null;
/** @type {StudentDataImpl} */
let StudentRepository = null;

/** @type {ExamRepositoryImpl} */
let ExamRepository = null;

/** @type {AttemptRepositoryImpl} */
let AttemptRepository = null;

/** @type {AttemptRegistryClass} */
let AttemptRegistry = null;

/** @type {google.visualization} */
let gviz  = null;

export {
  StudentData,
  StudentRepository,
  ExamRepository,
  AttemptRepository,
  AttemptRegistry,
  gviz,

  setStudentData,
  setExamRepository,
  setAttemptRepository,
  setAttemptRegistry,

  setGviz,

};

function setStudentData(instance) {
  StudentData = StudentRepository = instance;
}

function setExamRepository(instance){
  ExamRepository = instance;
}

function setAttemptRepository(repo){
  AttemptRepository = repo;
}

function setAttemptRegistry(registry){
  AttemptRegistry = registry;
}

function setGviz(_gviz){
  gviz = _gviz;
}