import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client/core";

const httpLink = createHttpLink({
  // uri: `http://localhost:4000/graphql` // our Session API lives there
  uri: `https://dancing-orca.studentrdh.com/graphql`, // our Session API lives there
});

const cache = new InMemoryCache();

export const apolloClient = new ApolloClient({
  link: httpLink,
  cache,
  connectToDevTools: true,
});
