<template>
  <div class="cj-performance-se">
    <main v-if="state === 'ready'" class="container page">
      <ul class="nav nav-tabs" style="margin-top: 48px;">
        <li class="nav-item" v-for="ex in exams" :key="ex.id">
          <router-link 
            class="nav-link" 
            :to="{ name: 'performance_se', params: { student_id: student.id, exam_id: ex.id } }"
          >{{ex.compactTitle}}</router-link>
        </li>

        <li class="nav-item" style="flex-grow: 1"></li>
        <li class="nav-item">
          <router-link class="nav-link" :to="{ name: 'performance_s', params: { student_id: student.id } }">Overall Performance</router-link>
        </li>
      </ul>

      <div class="row" style="margin-top: 32px;">
        <div class="col-12">
          <h2>Most recent attempt</h2>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <LastScoreWidget :last-attempt="lastAttempt"/>
        </div>
      </div>

      <!-- <div class="row xspace mt-4">
        <div class="col-xs-12 col-md-4 d-none d-md-block">
          ...
        </div>
        <div class="col-xs-12 col-md-8" xxstyle="height: 490px;">
          <AttemptCategoryBreakdownGc :last-attempt="lastAttempt"/>
        </div>
      </div> -->
      
      <div class="row xspace mt-4">
        <div class="col-12" xxstyle="height: 490px;">
          <AttemptCategoryBreakdownGc :last-attempt="lastAttempt"/>
        </div>
      </div>
      
      <div class="row">
        <div class="col">
          <LEA_CategoriesWidget :last-attempt="lastAttempt"/>
        </div>
      </div>

      <div class="row" style="margin-top: 32px;">
        <div class="col-12">
          <h2>Historical Performance</h2>
        </div>
      </div>

      <div class="row">
        <div class="col"><h3>Attempt history</h3></div>
      </div>

      <div class="row">
        <div class="col"><LastExamAttempts :student="student" :exam_id="exam_id"/></div>
      </div>


      <div class="row">
        <div class="col"><div class="widget-small" ref="attemptScoreChartContainer"></div>  </div>
        <div class="col"><div class="widget-small" ref="attemptDurationChartContainer"></div></div>
      </div>

      <div class="row">
        <div class="col"><h3>Attempts across all exams</h3></div>
      </div>
      <div class="row">
        <div class="col">
          <article class="widget-small" ref="chart-target"></article>
        </div>
      </div> 
      <!--
      -->

      <!-- <div class="row">
        <div class="col-sm"><article class="widget-small"></article></div>
        <div class="col-sm"><article class="widget-small"></article></div>
        <div class="col-sm"><article class="widget-small"></article></div>
      </div> -->

    </main>
    <main v-else-if="state === 'empty'" class="container page">
      <ul class="nav nav-tabs" style="margin-top: 48px;">
        <li class="nav-item" v-for="ex in exams" :key="ex.id">
          <router-link 
            class="nav-link" 
            :to="{ name: 'performance_se', params: { student_id: student.id, exam_id: ex.id } }"
          >{{ex.compactTitle}}</router-link>
        </li>

        <li class="nav-item" style="flex-grow: 1"></li>
        <li class="nav-item">
          <router-link class="nav-link" :to="{ name: 'performance_s', params: { student_id: student.id } }">Overall Performance</router-link>
        </li>
      </ul>
      <div class="container">
        <h2 style="margin-top: 36px;">Student did not attempt this exam yet</h2>
      </div>
    </main>
    <main v-else-if="state === 'error'">
      <h2>Error</h2>
      <pre>{{error.message}}</pre>
    </main>
    <main v-else class="container page">
      <h2>Loading...</h2>
      <pre>{{state}}</pre>
    </main>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */ 
/* eslint-disable vue/no-unused-components */ 
/* eslint-disable no-debugger */ 
import { notNilAll } from '../../../utils/utils'
import { StudentRepository, AttemptRegistry, AttemptRepository, ExamRepository, gviz } from '../../../services'
import { Exam, Student } from '../../../entities'
import { AttemptColumns } from '@/entities/attempt.ent/attempt-columns'
import { format, formatRelative } from 'date-fns'
import LastScoreWidget from '../../../components/widgets/LastScoreWidget.vue'
import LastExamAttempts from '../../../components/widgets/LastExamAttempts.vue'
import LEA_CategoriesWidget from '../../../components/widgets/LEA_CategoriesWidget.vue'
import AttemptCategoryBreakdownGc from '../../../components/widgets/attempt/AttemptCategoryBreakdownGc.vue'
const State = {
  loading: 'loading',
  error: 'error',
  ready: 'ready',
  empty: 'empty',
};

export default {
  name: 'StudentExamPerformance',
  beforeDestroy () {
    console.log(`[Performance_SE]: beforeDestroy()`);
  },
  async created () {
    await this.initialize();
  },
  components: {
    LastScoreWidget,
    LastExamAttempts,
    LEA_CategoriesWidget,
    AttemptCategoryBreakdownGc,
  },
  computed: {
    exam_id(){
      return this.$route.params.exam_id;
    },
    name() {
      return this.student.name;
    },
    lastAttemptDate(){
      return format(this.lastAttempt.TimeSubmittedAt, 'MMMM d');
    }

    // isReady() {
    //   return notNilAll(
    //     this.student,
    //     this.lastAttemptPercentage,
    //     this.lastAttemptQuestionsAnsweredCorrectly,
    //     this.lastAttemptQuestionsTotal
    //   )
    // },
  },
  data() {
    return {
      isActive: false,
      state: State.loading,
      error: null,

      student: null,
      attempts: null,
      lastAttempt: null,

      exams: null,

      // lastAttemptPercentage: null,
      // lastAttemptQuestionsAnsweredCorrectly: null,
      // lastAttemptQuestionsTotal: null,
    }
  },
  // inject: ['gExams'],
  methods: {
    cleanup(){
      // should I remove charts? 
      this.isActive = false;
    },

    async initialize(){
      this.isActive = true;
      const { student_id , exam_id }  = this.$route.params;

      try{
        const p = this.loadExams();
        {
          await this.loadStudent({ student_id });
          await this.loadStudentLastAttempt({ student: this.student, exam_id });
        }
        await p;
        this.lastAttempt ? this.setReady() : this.setEmpty();
      }
      catch(e){
        this.setError(e);
      }

      await this.loadAttemptHistoryAndRenderInlineCharts();       

    },

    async loadExams(){
      this.exams = await ExamRepository.findAll();
      return this.exams;
      // this.exams = await StudentRepository.listExams({ initiatedBy: 'StudentExamPerformance#loadExams()'});
      // this.gExams.splice(0, this.gExams.length);
      // this.gExams.push(...this.exams);
    },
    /**
     * @returns {Promise<Student>}
     */
    async loadStudent({ student_id }) {
      return this.student = await StudentRepository.findStudent(student_id, { initiatedBy : `StudentExamPerformance#loadStudent()` });
    },

    /**
     * @param {Object} params
     * @param {Student} params.student
     * @param {String} params.exam_id
     */
    async loadStudentLastAttempt({ student, exam_id }){
      // what should we do here?
      // i need to load the last score... 
      // which verbs do I have for the last attempts? 
      // i shoud be able to query attempts, but only for single device. 
      this.lastAttempt = AttemptRegistry.getBy({ 
        studentFullName: student.fullName,
        examId: exam_id,
      })
      // what if we have no attempts? 

    },

    async loadAttemptHistoryAndRenderInlineCharts(){
      const { DataTable, DataView, Table, ColorFormat, BarChart, ColumnChart } = gviz;
      const { COL_ID, COL_SCORE_PERCENT, COL_TIME_STARTED_AT } = AttemptColumns;

      // this statement is ALWAYS FALSE as refs are not availble at the time of call
      // if ( 
      //   !this.$refs.attemptScoreChartContainer 
      // ) return console.error(`Cannot render charts as refs with container elements are not available.`);

      // whime? 
      // query repo for the history
      // this.attempts = await AttemptRepository.findBy({ 
      const attemptsDataTable = await AttemptRepository.findBy({ 
        studentFullName: this.student.fullName, 
        examId: this.exam_id,
      }, { 
        asDataTable: true,
        initiatedBy : 'StudentExamPerformance#loadAttemptHistory()'
      });

      if ( 0 == attemptsDataTable.getNumberOfRows()){
        return console.warn(`Cannot render charts as there are no attempts for this student+exam combination`);
      }

      // adds formatting to .timeStartedAT and .scorePercent columns
      {
        const now = new Date();
        for(let r = 0 ; r < attemptsDataTable.getNumberOfRows(); r++){
          // add formatting for timeStartedAT
          attemptsDataTable.setCell(
            r,
            COL_TIME_STARTED_AT,
            undefined /* undefined: leave primary value as is; null : resets primary value */,
            formatRelative(attemptsDataTable.getValue(r,COL_TIME_STARTED_AT), now, { weekStartsOn: 1 /* Mon */})
          );

          // add formatting for scorePercent (so far looks like it affects tooltip for the column)
          attemptsDataTable.setCell(
            r,
            COL_SCORE_PERCENT,
            undefined /* undefined: leave primary value as is; null : resets primary value */,
            attemptsDataTable.getValue(r,COL_SCORE_PERCENT) + '%',
          );          
        }
      }
      
      // console.log('attemptsDataTable:',JSON.parse(attemptsDataTable.toJSON()));

      // for(let r = 0 ; r < dt.getNumberOfRows() ; r++){
      //   // dt.setValue(r, 0 /* timeStartedAt */, `Hello row ${r}`); //
      //   dt.setValue(r, 2 /* StartedAtMoment:String */, `Hello row ${r}`); //
      //   // dt.setValue(r, 2 /* StartedAtMoment:String */,  r); 
      //   // dt.setCell(r, 2 /* StartedAtMoment:String */,  r, `Date ${r}`); 
      // }

      drawScoresChart(attemptsDataTable, this.$refs.attemptScoreChartContainer);
      drawDurationChart(attemptsDataTable, this.$refs.attemptDurationChartContainer);

      function drawScoresChart(attemptsDataTable, container){
        if (!container) return console.warn(`Cannot draw <Attempt Scores Chart> because container is not specified.`);

        const scoresDataView = new DataView(attemptsDataTable);
        scoresDataView.setColumns([
          COL_TIME_STARTED_AT,
          COL_SCORE_PERCENT,
          // Re calculated column @see https://developers.google.com/chart/interactive/docs/gallery/columnchart?hl=en#column-styles
          // adding to column label an '%' suffix
          {
            // calc: 'stringify',   
            calc: function(dataTable, rowId){
              // console.log(`arguments:` , Array.from(arguments));
              return `${dataTable.getValue(rowId, COL_SCORE_PERCENT)}` + '%';
            },
            sourceColumn: COL_SCORE_PERCENT,
            type: 'string',
            role: 'annotation', // important: to actually make it appear as label for ColumnChart
          }
        ]);

        // const scoresDataTable = (()=>{
        //   const dt = scoresDataView.toDataTable();
        //   for(let r = 0 ; r < dt.getNumberOfRows() ; r++){
        //     dt.setCell(
        //       r, 
        //       0 /* timeStartedAt */,
        //       undefined /* leave as is */,
        //       formatRelative(dt.getValue(r,0), new Date(), { weekStartsOn: 1 /* Mon */})
        //       // `===${dt.getFormattedValue(r,0)}===`
        //       // `===${dt.getValue(r,0)}===`
        //       );
        //   }
        //   return dt;
        // })();        

        const scoresChart = new ColumnChart(container);
        scoresChart.draw(scoresDataView, {
        // scoresChart.draw(scoresDataTable, {
          bars: 'vertical', // only for Material charts
          title: 'Scores of student attempts (%)',
          width: '100%',
          hAxis: { type: 'category' },
          legend: { position: 'none' },
          animation: { startup: true },
          chartArea: { 
            backgroundColor: 'gainsboro',
            left: '10%',
            top: 40,
            width: '80%',
            // width: 0,
            height: '70%',
          },
          // annotations: { alwaysOutside: true },
        });
      }

      function drawDurationChart(attemptsDataTable, container){
        if (!container) return console.warn(`Cannot draw <Attempt Duration Chart> because container is not specified.`);

        const durationDataView = new DataView(attemptsDataTable);
        // durationDataView.setColumns([7, 10]); // 10: duration seconds
        durationDataView.setColumns([7, 9]);     // 9: duration minutes

        const durationChart = new ColumnChart(container);
        durationChart.draw(durationDataView, {
          bars: 'vertical', // only for Material charts
          title: 'Durations of student attempts (min)',
          width: '100%',
          hAxis: { type: 'category' },
          legend: { position: 'none' },
          animation: { startup: true },
          series: [  // @see series docs are after 'reverseCategories' within https://developers.google.com/chart/interactive/docs/gallery/columnchart?hl=en#configuration-options
            { color: 'teal'},
            {}
          ],
          // annotations: { alwaysOutside: true },
        });
      }

    },

    doQuery(){
      console.warn(`doQuery()`, new Error().stack); 
      // @see https://developers.google.com/chart/interactive/docs/reference#Query
      // @see https://github.com/ctd-tech/connector-app/blob/011ec3a267c6f0ef8f374ef17520c4e5c49251cf/src/services/google-charts/data-table-loader.js#L12
      const { Query, QueryResponse, QueryWrapper, CustomQuery, DataTable, Table } = gviz;
      // let query = new Query('https://docs.google.com/spreadsheets/d/1UytyZTU1NGpAQJ-kii1Aw3lvLz3B1irYn1dcvD3bUdw/edit#gid=0', {
      let query = new Query('https://docs.google.com/spreadsheets/d/1UytyZTU1NGpAQJ-kii1Aw3lvLz3B1irYn1dcvD3bUdw/edit?gid=0', {
        
      });
      // query.setQuery(`select A,B,C, D where D = 'Kanye West' `);
      query.setQuery(`select A,B,C, D where D = '${this.student.fullName}' `);
      query.send(response=>{
        // how do we handle response
        if ( response.isError() ){
          throw new Error(`Error querying google sheet:`, response.getDetailedMessage());
        }
        console.log(`QueryResponse:`, response);
        let dt = response.getDataTable();
        console.log(`QueryResponse.getDataTable():`, dt);

        this.runOnlyWhenActive(()=>{
          const chartTargetEl = this.$refs['chart-target'];
          
          let tableChart = new Table(chartTargetEl);
          tableChart.draw(dt, { 
            showRowNumber: true,
            width: '100%',
            // height: '100%',
          })
        })

      })
    },

    runOnlyWhenActive(callback, ...args){
      if ( !this.isActive ) return console.warn(`Skipping running function because component is not active.`);
      callback.apply(this, args);
    },

    /**
     * @param {Error}
     */
    setError(e){
      // do we switch to the error state? 
      this.state = State.Error;
      this.error = e;
    },

    setReady(){
      this.state = State.ready;
    },

    setLoading(){
      this.state = State.loading; 
    },

    setEmpty(){
      this.state = State.empty;
    },

    // async loadGoogleVisualization(){
    //   return new Promise((resolve, reject)=>{
    //     // eslint-disable-next-line
    //     google.load('visualization', '1', {
    //       packages: ['corechart', 'table', /* 'controls' */],
    //       callback: (err)=>{
    //         if ( err ) return reject(err);
    //         // eslint-disable-next-line
    //         resolve(google.visualization);
    //       },
    //     })
    //   });
    // }
  },
  mounted () {
    // Probably better poll if component is actually available,
    // @see via https://github.com/vuejs/vue/issues/2918#issuecomment-457812528
    this.$nextTick(()=>{
      setTimeout(()=>{
        this.doQuery();
      }, 2500);
    })

    // alert(`Name is: [${this.name}]`); // doesn't seem to contain the name of the component :(
  },
  watch: {
    $route(toRoute, fromRoute) {
      console.log('toRoute:', toRoute);
      console.log('fromRoute:', fromRoute);
      if ( toRoute.name !== fromRoute.name ) return this.cleanup(); // we are leaving this route, so no need to reload anything. (Rather maybe cleaneup) 
      this.initialize(); // shoudn't we first reset all the data?
      this.doQuery();
    }
  },
}
</script>

<style scoped>
h3{
  margin-top: 20px;
  margin-bottom: 10px;
}

.space{
  margin-top: 32px;
}
</style>