import gql from "graphql-tag";
export const MUTATION__CREATE_STUDENT_GROUP = gql`
  mutation CreateStudentGroupFromNameAndTenantUuid($name: String!, $tenantUuid: String!) {
    studentGroup: createStudentGroupFromNameAndTenantUuid(name: $name, tenant_uuid: $tenantUuid) {
      uuid
      name
      tenant_uuid
    }
  }
`;

