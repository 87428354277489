<template>
  <div>
  <h3>Here's Student Report [{{msg}}]</h3>
  <hr/>
  <article v-for="t in todos" :key="t.id">
    <span>{{t.name}}</span>
    <i>{{t.description}}</i>
  </article>
  <hr/>
  <form>
  <input type="text" v-model="name" placeholder="Enter name">
  <input type="text" v-model="description" placeholder="Enter description">
  </form>
  <button v-on:click="createTodo">Create</button>
  <button v-on:click="getTodos">List</button>
  <div>
    <ul>
      <li>{{name}}</li>
      <li>{{description}}</li>
    </ul>
  </div>

<!-- <iframe 
width="100%" 
height="100%" 
src="https://datastudio.google.com/embed/reporting/864b7ce2-f15d-4365-bcaa-1eb78ea88980/page/ePRmC" 
frameborder="0" 
style="border:0" 
allowfullscreen
></iframe> -->
  </div>
</template>

<script>
import { API } from 'aws-amplify'
import { createTodo } from '../../graphql/mutations'
import { listTodos } from '../../graphql/queries'
import { onCreateTodo } from '../../graphql/subscriptions'


export default {
  name: 'HelloGraphQL',
  props: {
    msg: String,
  },
  created(){
    this.subscribe();
  },
  data() {
    return {
      name: '',
      description: '',
      todos: [{
        id: 'idDummy',
        name: 'Dummy',
        description: 'Dummy description',
      }],
    }
  },
  methods: {
    async getTodos(){
      const result = await API.graphql({
        query: listTodos,
      })
      console.log(`listTodos() result:`, result);
      const { items } = result.data.listTodos;
      this.todos = items;
    },
    async createTodo(){
      const { name, description} = this;
      if ( !name || !description ) return alert('missing name or description');
      const todo = { name, description };
      await API.graphql({
        query: createTodo,
        variables: { input : todo }
      });

      this.name = this.description = '';
    },
    async subscribe(){
      const apiCall = API.graphql({
        query: onCreateTodo
      });

      apiCall.subscribe({ 
        next(eventData){
          const item = eventData.value.data.onCreateTodo;
          console.log('Created new item: ' + JSON.stringify(item))
          alert('Created new item: ' + JSON.stringify(item))
        }
      })
    }
  },

}
</script>

<style scoped>

</style>