<template>
  <div class="cj-widget-lea-categories widget-small">
    <div v-if="isReady"  class="cj-widget-lea-categories__container">
      <table class="table table-light">
        <thead>
          <th>Category</th>
          <th>Score</th>
          <th class="head-c">Correct / Total </th>
        </thead>
        <tbody>
          <tr v-for="(cat,i) in significantCategories" :key="i">
            <!-- <td> <img class="cj-widget-lea-categories__cell-image" :src="cat.imageUrl"/> </td> -->
            <td class="cell cell-a">{{ cat.title }}</td>
            <td class="cell cell-b">{{ getCategoryScoreValue(cat.id) }}</td>
            <td class="cell cell-c">{{ getCategoryQuestionMetricsValue(cat.id) }}</td>
          </tr>
        </tbody>
      </table>

    </div>
    <div v-else>
      <h3>Data not available or failure loading categories</h3>
    </div>

  </div>
</template>

<script>
  import { Attempt, Category } from '../../entities/attempt.ent'
  import gql from 'graphql-tag'
  // import { categories } from '../../entities/lib/category-metadata'
  const NA_LABEL = '-';

  const LIST_CATEGORIES = gql`query {
    categories : listCategories {
      id
      title
      imageFilename
      imageUrl
    }
  }
  `;

  export default {
    name: 'Lea_CategoriesWidget',

    apollo: {
      categories: LIST_CATEGORIES,
    },

    computed: {
      /** Categories for which data is available (can be 0 answers, but data is available) */
      significantCategories() {
        return this.categories.filter(cat=>this.lastAttempt.retrieveCategory(cat.id));
      },
      isReady(){
        return this.lastAttempt && Array.isArray(this.categories);
      }
    },

    data() {
      return {
        categories: null, /* null signifies not loaded */
      }
    },
    methods: {
      getCategoryScoreValue(catId) {
        /** @type {Attempt} this.lastAttempt */
        const attempt = this.lastAttempt;
        return attempt.hasCategories() && attempt.retrieveCategory(catId) ?  `${attempt.retrieveCategory(catId).scorePercentInt}%` : '-';
        // return '10%';
      },

      getCategoryQuestionMetricsValue(catId){
        /** @type {Category} category */
        let category;
        if ( ! this.lastAttempt.hasCategories() ) return NA_LABEL;
        if ( ! ( category = this.lastAttempt.retrieveCategory(catId)) ) return NA_LABEL;
        return `${category.answeredCorrectly} / ${category.total}`;
      }
    },
    props: {
      lastAttempt: Object,
    },

  }
</script>

<style scoped>
.cj-widget-lea-categories{
  /* background: skyblue; */
  display: block;
  /* height: 600px; */
  overflow-y: scroll;
}

.cj-widget-lea-categories__container{

}

.cj-widget-lea-categories__cell-image{
  width: 100px;
  height: 100px;

}

.cell, .cell-a, .cell-b {
  text-decoration: none;
}

.cell-a{
  max-width: 240px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.cell-c, .head-c{
  text-align: center;
}
</style>