import {
  setStudentData,
  setAttemptRepository,
  setAttemptRegistry,
  setExamRepository,
  setGviz,
} from "./index";
import { StudentDataImpl } from "./student-data";
import { AttemptRepository } from "./attempt-repo";
import { ExamRepository } from "./exam-repository";
import { AttemptRegistry } from "./attempt-registry";

/**
 * Initializes all of the services...
 */
export async function init({ gExams }) {
  const gviz = await loadGoogleVisualizationApi();
  setGviz(gviz);

  const sheetId = "1UytyZTU1NGpAQJ-kii1Aw3lvLz3B1irYn1dcvD3bUdw";

  setStudentData(
    await StudentDataImpl.createAsync({
      gviz,
      sheetId,
      gid: 1323244833, // Sheet.Students
    })
  );

  let examRepository;
  setExamRepository(
    examRepository = await ExamRepository.createAsync({
      gviz,
      sheetId,
      gid: 433448846, // Sheet.Exams
      gExams,
    })
  );

  let attemptRepository;
  setAttemptRepository(
    (attemptRepository = await AttemptRepository.createAsync({
      gviz,
      sheetId,
      gid: 0, // Sheet.Attempts
    }))
  );

  setAttemptRegistry(
    await AttemptRegistry.createAsync({
      AttemptRepository: attemptRepository,
      ExamRepository: examRepository,
    })
  );
}

/**
 * #return {google.visualization.DataTable}
 * @return {google.visualization}
 */
async function loadGoogleVisualizationApi() {
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line
    google.load("visualization", "1", {
      packages: [
        "corechart",
        "table",
        "gauge", // @see https://developers.google.com/chart/interactive/docs/gallery/gauge
        // 'controls',
      ],
      callback: (err) => {
        if (err) return reject(err);
        // console.log('google.visualization keys:',Object.keys(google.visualization));
        // console.log('google.visualization:',google.visualization);
        const chartConstructorNames = Object.keys(google.visualization)
            .filter(s=>s.endsWith('Chart'))
            .filter(s=>s.charAt(0)<='Z');

        chartConstructorNames.push('Table'); // !!!adds Table manually as it is a chart, but doesn't have suffix 'Chart'

        for(let [name,konstructor] of Object.entries(google.visualization)){
          if ( !chartConstructorNames.includes(name) ) continue;
          const konstructor2 = function(...args){
            
            console.log(`google.visualization.${name}(${args}), args.length=${args.length}`);
            
            if ( ! (args[0] instanceof HTMLElement  ) ){
              const e = new Error(`Charts must have an HTMLElement as constructor parameter. You suppled [${args[0]}]`);
              console.error(e.stack);
              throw e;
            }
            // return konstructor.bind(this)(...args);
            return konstructor.apply(this, args);
            // return konstructor(...args);
          }

          konstructor2.prototype = Object.create(konstructor.prototype);
          google.visualization[name] = konstructor2;
        }
        // eslint-disable-next-line
        resolve(google.visualization);
      },
    });
  });
}
