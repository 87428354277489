import { keyBy, isUndefined, isString, get } from "lodash-es";
import { CategoryMetric } from "../category-metric.ent";
import { Attempt } from "../attempt.ent";
import { AttemptColumns } from "./attempt-columns";

export function µFromDataRow({ categoryDefinitions }) {
  return (rowData) => {
    const {
      COL_ID,

      COL_EXAM_ID,
      COL_EXAM_NAME,

      COL_FULL_NAME,

      COL_QUESTIONS_ANSWERED_CORRECTLY,
      COL_QUESTIONS_SKIPPED,
      COL_QUESTIONS_TOTAL,
      // COL_SCORE_PERCENT,

      // COL_TIME_DURATION_MINUTES,
      // COL_TIME_DURATION_SECONDS,
      COL_TIME_STARTED_AT,
      COL_TIME_SUBMITTED_AT,
    } = AttemptColumns;

    // console.log(`Creating Attempt from rowData:`, rowData);

    const attempt = new Attempt({
      StudentAttempt: rowData[COL_ID],
      ExamId: rowData[COL_EXAM_ID],
      ExamName: rowData[COL_EXAM_NAME],
      StudentFullName: rowData[COL_FULL_NAME],
      QuestionsAnsweredCorrectly: rowData[COL_QUESTIONS_ANSWERED_CORRECTLY],
      QuestionsSkipped: rowData[COL_QUESTIONS_SKIPPED],
      QuestionsTotal: rowData[COL_QUESTIONS_TOTAL],
      TimeStartedAt: rowData[COL_TIME_STARTED_AT],
      TimeSubmittedAt: rowData[COL_TIME_SUBMITTED_AT],
    });

    // process categories
    // "Cat_AAP": "AAP : [0,4,5]",
    // "Cat_Community": "Community : [4,4,5]",
    // "Cat_DA": "DA : [0,3,4]",
    // "Cat_DB": "DB : [3,0,3]",
    const categories = Object.entries(rowData)
      .filter(([colName, serialized]) => colName.startsWith("Cat_"))
      .filter(([colName, serialized]) => Boolean(null !== serialized)) // remove categories which have no values (null values)
      .map(([colName, serialized]) => {
        if ( isUndefined(colName) ){
          debugger;
        }
        const catMetric = CategoryMetric.fromSerialized(serialized, {
          columnName: colName,
          categoryMetaDefinitionsIndexedById: keyBy(categoryDefinitions, "id"),
        });
        return catMetric;
      });

    if (!categories.length) {
      // currently, the only situation when this would run, would be when COLUMNS ARE MISSING!
      // keep in mind COLUMNS not CELL VALUES! Which means that this does NOT detect empty values...
      attempt.setCategories(false);
    } else {
      // TODO: it turns out that categories CAN BE PARTIAL (and it makes sense... some exams won't have certain categories...)
      attempt.setCategories(
        keyBy(categories, "id") // What is the data structure? (accepts array of Array<CatEntry> , where CatEntry = { cat_id: String, ...somethign }
        // returns { [cat_id0]: { cat_id: ..., something ....}, { cat_id: ..., something ...}}
      );
    }

    return attempt;
  };
}
