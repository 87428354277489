<template>
  <main class="container co-stack" style="height: 100%">
    <h2>My Student Groups</h2>
    <!-- <controls>
      <button class="btn btn-primary">Hello</button>
    </controls> -->
    <div class="xco-container" cardboard>
      <section v-if="studentGroups">
        <ul v-if="studentGroups.length">
          <li v-for="gr in studentGroups" :key="gr.uuid">
            <router-link :to="'/my/student-groups/' + gr.uuid">
              {{gr.name}}
            </router-link>
          </li>
        </ul>
        <div v-else>
          <p>Your account does not have any student groups. </p>
          <p>Constact administrator to assign students to your account</p>
        </div>
        
      </section>
      <section v-else>
        <h4 style="margin-top: 32px">Loading student groups...</h4>
      </section>
    </div>
  </main>
</template>

<script>
import { Auth } from 'aws-amplify'
import { GROUPS_OF_TENANT } from '../tenant-management/gql/groups-of-tenant.gql'
export default {
  // apollo: {
  //   studentGroups: {
  //     query: GROUPS_OF_TENANT,
  //     variables: {
  //       tenantUuid: 'ot333clontarf',
  //       // tenantUuid: 'how do I get tenant uuid? ',
  //       // tenantUuid: 'how do I get tenant uuid? ',
  //       // tenantUuid: 'how do I get tenant uuid? ',
  //     },
  //     update: function(data){ 
  //       return data.groupsOfTenant.map(gr=>({...gr}));
  //     }
  //   }
  // },
  
  async created () {
    // how do I get tenant id? 
    const caUser = await Auth.currentAuthenticatedUser();
    const tenantUuid = tenantIdFor(caUser);
    this.studentGroups = await this.listGroupsOfTenant(tenantUuid)
  },

  data() {
    return {
      studentGroups: null,
    }
  },
  methods: {
    async listGroupsOfTenant(tenantUuid) {
      const { data, error } = await this.$apollo.query({
        query: GROUPS_OF_TENANT,
        variables: {
          tenantUuid,
        },
      });

      if ( error ) {
        console.error(`Error listing tenant groups:`,error);
        throw new Error(`Error listing tenant groups:`);
      }

      // why make shallow copy?
      return data.groupsOfTenant.map(gr=>({...gr}));
    }
  },
  
}

/**
 * 
 * @param {import('amazon-cognito-identity-js').CognitoUser} user 
 */
function tenantIdFor(user){
  if ( user.attributes['custom:tenant'] ){
    return user.attributes['custom:tenant'];
  }
  else{
    console.log(`cognitoUser:`, user);
    throw new Error(`Cannot find custom attribute 'user.attributes['custom:tenant']' on user logged to console.`);
  }
}
</script>

<style lang="css" scoped>

</style>