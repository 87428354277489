export const AttemptColumns = {
  COL_ID: 0,
  COL_EXAM_NAME: 1,
  COL_EXAM_ID: 2,
  COL_FULL_NAME: 3,
  COL_SCORE_PERCENT: 4,
  COL_QUESTIONS_ANSWERED_CORRECTLY: 5,
  COL_QUESTIONS_SKIPPED: 6,
  COL_QUESTIONS_TOTAL: 7,
  COL_TIME_STARTED_AT: 8,
  COL_TIME_SUBMITTED_AT: 9,
  COL_TIME_DURATION_MINUTES: 10,
  COL_TIME_DURATION_SECONDS: 11,
};
