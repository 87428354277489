<template>
  <div class="co-container-two-columns">
  <!-- <pre>**{{selectedGroup}}**</pre> -->
  <!-- <section class="co-panel">
    <ItemList :items="pStudentGroups" title="Groups"/>
  </section> -->
  <!-- <section class="co-panel">
    <ItemList :items="studentGroups" title="Groups"/>
  </section> -->

  <section class="co-panel">
    
    <ItemList 
        v-if="selectedGroup" 
        :items="membersOfGroup" 
        :loadingMessage="'Loading members of group' + selectedGroup.name"
        :title="'Members of group `' + selectedGroup.name + '`'"
        />
    <h3 v-else>Please select group to see members</h3>
  </section>

  <section class="co-panel">
    <ItemList :items="pAllStudents" title="All Students"/>
  </section>
</div>

</template>

<script>
import gql from 'graphql-tag'
import ItemList from './ItemList/ItemList.vue';
import { StudentGroupPresenter } from './presenters/student-group.presenter'
import { StudentPresenter } from './presenters/student.presenter'
import { MUTATION__ADD_MEMBER_TO_STUDENTGROUP } from './gql/add-member-to-studentgroup.gql'
const Presenters = {
  StudentGroup: StudentGroupPresenter,
  Student: StudentPresenter,
};

const STUDENTGROUP_DIRECTORY = gql`query studentGroupDirectory($groupUuid: String!){
  students:  listItemsOfStudentGroup(group_uuid: $groupUuid) {
    uuid
    first_name
    last_name
    email
    graduation
    school
    state
  }
}
`;

const STUDENTGROUPS_LIST = gql`query studentGroups {
  studentGroups:  listStudentGroups {
    uuid
    name
    tenant_uuid
  }
}`;



const LIST_ALL_STUDENTS = gql`
  query ListStudents {
    allStudents: listStudents {
      uuid
      first_name
      last_name
      email
      graduation
      school
      state
    }
  }
`;


export default {
    name: "StudentGroupsListContentsOf",
    apollo: {
      // allStudents: LIST_ALL_STUDENTS,
      pAllStudents: { 
        query: LIST_ALL_STUDENTS,
        update: function(data){
          return data.allStudents.map(st=>Presenters.Student.createFromStudent(st, {
            actionsPrimary: [
              this.createAction_AddStudentToGroup(),
            ],
          }));
        },
      },
      studentGroups: STUDENTGROUPS_LIST,
      pStudentGroups: {
        query: STUDENTGROUPS_LIST,
        update: function(data){
          // debugger;
          return data.studentGroups.map(Presenters.StudentGroup.µFromStudentGroup({ 
          hello: 'world',
          actionsPrimary: [
            this.createAction_SelectGroup(),
          ]}));
        },// update(data)
      },
    // students: STUDENTGROUP_DIRECTORY,
    },
    components: { ItemList },
    computed: {
      // Presenters
      // pStudentGroups() {
      //   // try to cache this computed property
      //   if  (null === this.data._pStudentGroups){
      //     this.data._pStudentGroups = !this.studentGroups ? null : this.studentGroups.map(Presenters.StudentGroup.µFromStudentGroup({ 
      //     hello: 'world',
      //     actionsPrimary: [
      //       handle
      //     ]}));
      //   }

      //   if ( null === this._pStudentGroups) {
      //     return []
      //   }
      //   return this._pStudentGroups;
        
      // }
      selectedGroup(){
        if ( !this.studentGroups ){
          return null;
        }
        const group_uuid = this.$route.params.group_id;
        return this.studentGroups.find(gr=>group_uuid===gr.uuid);
      }
      
    },
    created() {
      const group_uuid = this.$route.params.group_id;
      debugger;
      this.fetchStudentGroupDirectory(group_uuid).then(students=>{
        debugger;
        this.membersOfGroup = students;
      });
    },
    data() {
        return {
            // allStudents: [
            //   { uuid: "tTENANT:uDUMMYUSER1", full_name: "Dummy User1" },
            //   { uuid: "tTENANT:uDUMMYUSER2", full_name: "Dummy User2" },
            //   { uuid: "tTENANT:uDUMMYUSER3", full_name: "Dummy User3" },
            //   { uuid: "tTENANT:uDUMMYUSER4", full_name: "Dummy User4" },
            //   { uuid: "tTENANT:uDUMMYUSER5", full_name: "Dummy User5" },

            // ],

            // TODO: let's try to make it computed()
            // TODO: let's try to make it computed()
            // TODO: let's try to make it computed()
            // TODO: let's try to make it computed()
            // selectedGroup: null,



            membersOfGroup: null, // can it have 
            membersOfGroupLabel: null, // null by default
            // membersOfGroup: [
            //     { uuid: "tTENANT:uDUMMYUSER", full_name: "Dummy User" },
            // ],
        };
    },
    methods: {
        createAction_SelectGroup(){
          const that = this;
          const actionSelectGroup = {
            label: 'Select',
            id: 'IDactionSelectGroup',
            executorFn: (presenter)=>{
              // what do we do here ?
              // alert('calling actionSelectGroup() with presenter.id=' + presenter.id);
              // debugger;
              that.selectedGroup = presenter.item; // is this the group item? (but in this case it's not really a reactive one..)
              // how do I set things within component? 
              // I need to call methods of the component actually... 
            },
          };
          return actionSelectGroup;
        },
        createAction_AddStudentToGroup(){
          const that = this;
          const addStudentToGroup = {
            label: 'Add to group',
            id: 'IDactionSelectGroup',
            executorFn: (presenter)=>{
              // what do we do here ?
              // alert('calling actionSelectGroup() with presenter.id=' + presenter.id);
              // debugger;
              if ( !that.selectedGroup ){
                return alert(`Before adding user, please make sure that you have selected destination group`);
              }
              if ( !confirm(`Are you sure you want to add student '${presenter.label}' to group '${this.selectedGroup.name}'`) ){
                return;
              }

              const selectedGroup = that.selectedGroup;
              
              // need to call actual method 
              that.addStudentToGroup({
                student: presenter.item, 
                studentGroup: selectedGroup, 
              })
              .then(result=>{
                console.info(`Successfully added user to a group, result:`, result);
                // alert(`Successfully added student to a group!`);
              })
              .then(()=>{
                return that.fetchStudentGroupDirectory(selectedGroup.uuid, { fetchPolicy: 'no-cache' });
              })
              .then(students=>{
                that.membersOfGroup = students;
              })
              .catch(err=>{
                 console.error(`Error whilst adding student to a group:`, err);
                 alert(`Error whilst adding student to a group!`);
              })
               // do I handle error here somehow? (in case tehre's an arrey)
              // that.selectedGroup = presenter.item; // is this the group item? (but in this case it's not really a reactive one..)
              // how do I set things within component? 
              // I need to call methods of the component actually... 
            },
          };
          return addStudentToGroup;
        },

        // Handles
        handleGroupClick(sg){
          alert(`Clicking on student group...`)
        },
        handleStudentClick(st) {
            alert("Selected student: " + st.uuid);
        },

        // Data loading 
        async fetchStudentGroupDirectory(group_uuid, queryParams = {}) {
            const apolloQueryResult = await this.$apollo.query({
                query: STUDENTGROUP_DIRECTORY,
                // client: 'not-sure-what-goes-here',
                // For passing variables @see https://stackoverflow.com/questions/52098242/graphql-vue-this-apollo-query-doesnt-work-with-parameter-variables 
                variables: {
                    "xxgroupUuid": "gr4VjwdN1RiF",
                    "groupUuid": group_uuid,
                },
                ...queryParams,
            });
            console.log(`apolloQueryResult: `, apolloQueryResult);
            if (apolloQueryResult.error) {
                console.error(apolloQueryResult.error);
                throw new Error(apolloQueryResult.error.name);
            }
            const { students } = apolloQueryResult.data;
            // debugger;
            // TODO: this is where I can turn into (Presenters)
            // this.students = students;
            return students.map(st=>({
              ...st,
              id: st.uuid,
              label: `${st.first_name} ${st.last_name}`,
            }));
        },
        // Action heavy lifeters (the methods which actually do the work )
        async addStudentToGroup({ student, studentGroup }){
          // what do we do here? 
          // what is it that's going to work?
          // debugger;
          // TODO: what do we need to do here? 
          // we need to take the student and we need to call the mutation to add something to the thing
          const mutationResult = await this.$apollo.mutate({
            mutation: MUTATION__ADD_MEMBER_TO_STUDENTGROUP,
            variables: {
              "studentUuid": student.uuid,
              "groupUuid": studentGroup.uuid,
              // "studentUuid": "dominique.hunt.xWPhgaTI29xE2Tzu2PBvcA",
              // "groupUuid": "gr4VjwdN1RiF"
            },
            // client: 'student-group-management.view', StudentGroupsListContentsOf.vue?b0a3:193 Error whilst adding student to a group: Error: [vue-apollo] Missing client 'student-group-management.view' in 'apolloProvider'
          });

          console.log(`mutationResult:`, mutationResult);
          // const { addStudentGroupMember } = mutationResult.data;
          const { hasBeenAdded } = mutationResult.data;
          console.warn(`hasBeenAdded: ${hasBeenAdded}`);
        },
        handleRemoveStudentFromGroup(studentToRemove) {
            alert(`Removing student ${studentToRemove.uuid}`);
        }
    },
    watch: {
      // selectedGroup(newValue, oldValue) {
      //   console.warn(`watch.selectedGroup(${JSON.stringify(newValue)},${JSON.stringify(oldValue)})`);
      //   // debugger;
      //   if ( newValue ){ 
      //     // how to load students from the new group 
      //     this.fetchStudentGroupDirectory(newValue.uuid).then(students=>{
      //       this.membersOfGroup = students;
      //     });
      //   }
      // }
    },
}
</script>

<style scoped>
.co-panel{ 
  border: 1px solid gold;
  width: 50%;
}

.co-container-two-columns{
  display: flex;
  height: 100%;
}
</style>