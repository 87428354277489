<template>
  <div class="cj-student-list page">
    <div class="container">
      <!-- <h3>Student list</h3> -->
      <!-- <ul>
        <li v-for="st in students" :key="st.id">{{st.name}}</li>
      </ul> -->

      <table class="table table-hover cj-student-table">
        <thead>
          <th>#</th>
          <th>Id</th>
          <th>Email</th>
          <th>Student Name</th>
        </thead>
        <tbody>
          <tr v-for="(st,i) in students" :key="st.id">
            <td>{{i+1}}</td>
            <td>{{st.id}}</td>
            <td>{{st.email}}</td>
            <td>{{st.name}}</td>
          </tr>
        </tbody>
      </table>

    </div>
  </div>
</template>

<script>
import { StudentRepository } from '@/services'
export default {
  name: 'StudentList',
  created () {
    this.loadStudents();
  },
  data() {
    return {
      students: [],
    }
  },
  methods: {
    async loadStudents() {
      const students = await StudentRepository.findAll();
      this.students = students;
      // this.students.push({
      //   name: 'Bob',
      //   id: 'bob1',
      // })
    }
  },
  
}
</script>

<style scoped>
.cj-student-list{
  /* border: 1px solid gold; */
}
.cj-student-table{

}

</style>