// eslint-disable-next-line
// eslint-disable-next-line
import { Attempt, Exam } from '../entities';
import { verifyAll } from '../utils/verify';
import { groupBy, filter, isUndefined } from 'lodash-es'
import { AttemptRepository , ExamRepository } from './index'
import { AttemptAggregator} from './attempt-registry/attempt-aggregator'
export class AttemptRegistry {
  /**
   * 
   * @param {Object} params
   * @param {AttemptRepository} params.AttemptRepository
   * @param {ExamRepository} params.ExamRepository
   */
  constructor({ AttemptRepository, ExamRepository }){
    this.AttemptRepository = AttemptRepository;
    this.ExamRepository = ExamRepository;

    // this.attemtpsByStudent = {};
    this.attemptsBy__Exam_and_Student = null; // null means not initialized/preloaded yet..
    // this.attemptsBy__Exam_and_Student = {
    //   'ex123:Bob Marley': { /* attempt  */ },
    // };
  }

  async preloadData(){
    // here we preload the data...
    // NOTE: ord: 'DESC' is important!! As we assume descending order further below
    const allOrganicAttempts = await this.AttemptRepository.findAll({ ord: 'DESC' }, { initiatedBy: 'AttemptRegistry#preloadData()'});

    // TODO: need to query the NBDHE_ALL 
    // const NBDHE_ALL = {
    //   "title": "NBDH",
    //   "id": "exnbhdall",
    //   "compactTitle": "NBHE Overall",
    //   "kind": "container",
    //   "children": [
    //       "exnbdhe11",
    //       "exnbdhe12",
    //       "exnbdhe21",
    //       "exnbdhe22"
    //   ]
    // };

    // const containerExams = [
    //   NBDHE_ALL,
    // ];
    const NBDHE_ALL = await this.ExamRepository.findOneById({ exam_id: 'exnbhdall' });
    // TODO: how to load child exams? 
    await this._loadChildExams(NBDHE_ALL);
    // TODO: does it mean that this container exam is hardcoded? 
    const containerExams = [
      // await this.ExamRepository.getBy({ examId: 'exnbhdall' }),
      // await this.ExamRepository.findOneById({ exam_id: 'exnbhdall' }),
      NBDHE_ALL,
    ];

    // How do I load child exams too?
    let allCompoundAttempts = [];
    const groups = groupBy(allOrganicAttempts, 'StudentFullName');

    for(let oneStudentAttempts of Object.values(groups)){

      // We start and finish processing student attempts here... 
      const attemptAggregators = containerExams.map(containerExam=>AttemptAggregator.create({
        containerExam,
      })); 
      // I need to build some search map 
      const aggregatorsByChildExamId = Object.assign({}, ...attemptAggregators.map(ag=>ag.toMapByChildId()));
      // debugger; // TODO: let's make sure that our constuct here ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ is actually working

      // const dispatcher = CollectorDispatcher.fromArray([...attemptAggregators]); // this is a NOVEL abstraction... not sure how to vee it... (it's hard to vee it... it ) 
      // it was intented to "compact" finding of specific one... (but I think {dropping down} to more specific things may help... abstraction on abstraciton) 

      for(let att of oneStudentAttempts){
        const { ExamId } = att;
        const aggregator = aggregatorsByChildExamId[ExamId];
        if ( isUndefined(aggregator) ){
          continue; // none of our aggregators are interested in this exam (not child of any of the aggregators (aka {Exam Containers}))
        }
        aggregator.addAttempt(att);
      }

      const compoundAttempts = attemptAggregators.map(agg=>agg.toCompoundAttempt())
                                                  .filter(Boolean); // be sure to filter out nulls (which are for aggregators which never realized themselves [never aggregated anything])

      allCompoundAttempts = allCompoundAttempts.concat(compoundAttempts);

    }

    // index all the attempts by our composite key ExamID°StudentFullName
    const allAttempts = [...allOrganicAttempts, ...allCompoundAttempts];
    this.attemptsBy__Exam_and_Student = takeFirst(allAttempts, { 
      // key: ['ExamId', 'StudentFullName'],  // composite key from two props...
      key: att=>`${att.ExamId}:${att.StudentFullName}`,
      asArray: false,
    });


  }

  /**
   * Finds SINGLE last attempt (single last attempt if such exists) for given student+exam
   * @param {Object} params
   * @param {String} params.studentFullName
   * @param {String} params.examId
   * @returns {Attempt|null}
   */
  getBy({ studentFullName, examId }){
    if ( null === this.attemptsBy__Exam_and_Student) throw new Error(`Cannot use AttemptRegistry::getBy() before preloading data`);
    verifyAll(v=>{
      v.notNil(studentFullName, 'studentFullName');
      v.notNil(examId, 'examId');
    },'AttemptRegsitry.getBy()');

    return this.attemptsBy__Exam_and_Student[`${examId}:${studentFullName}`] || null;
  }

  static async createAsync(params){
    const registry = new AttemptRegistry(params);
    await registry.preloadData();
    return registry;
  }


  // TEMPORARY
  /**
   * 
   * @param {Exam} containerExam 
   */
  async _loadChildExams(containerExam){
    for (let childId of containerExam.children){
      const childExam = await this.ExamRepository.findOneById({ exam_id: childId });
      containerExam.addChild(childExam);
    }
  }
}


// ------------------------------------------------------
// UTILS: 
// ------------------------------------------------------
/**
 * Takes first elements which match the key (could be composite), assumes in descending order 
 * 
 * @param {Array} ar 
 * @param {Object} opt 
 * @param {Function|String[]} opt.key either function which generates key (entity)=>String key
 * @param {Boolean} opt.asArray either function which generates key (entity)=>String key
 * @returns {Object<Key,Element>} key as returned bye the opt.key function (eg. could be composite)
 */
function takeFirst(ar, opt){
  if ( !opt ) throw new Error(`opt parameter must be set.`)
  if ( !opt.key ) throw new Error(`opt.key must be set.`)
  if ( 'function' !== typeof opt.key ) throw new Error(`opt.key must be set to a function. Currently set to: ${typeof opt.key}`);

  const { key: keyFrom } = opt;
  const firstOnes = {

  };
  const firstOnesArray = [];

  for(let el of ar){
    const k = keyFrom(el);
    if ( firstOnes[k] ) continue;
    firstOnes[k] = el;
    firstOnesArray.push(el);
  }

  return opt.asArray ? firstOnesArray : firstOnes;
}