import { isNil } from 'lodash-es'

export function verifyAll(definitionFunction, messsagePrefix = ''){
  const verify = new Verifier();
  definitionFunction(verify);
  verify.verifyAll(messsagePrefix);
}

class Verifier{

  constructor(){
    this.tasks = [];
  }

  notNil(value, valueLabel){
    if ( !valueLabel ) throw new Error(`Please specify second parameter 'valueLabel' to notNil(xxx, valueLabel)`);
    this.tasks.push(()=>isNil(value) ? `value '${valueLabel}' cannot be nil` : false);
  }

  verifyAll(messagePrefix){
    const errors = this.tasks.map(t=>t()).filter(Boolean);

    if ( !errors.length ) {
      return;
    }
    throw new Error(`${messagePrefix}: ${errors.join('; ')}`)
  }
}