<template>
  <div>
    Notes components:
    <pre>{{$route.params.note_id}}</pre>
    <button class="btn btn-primary">Hello</button>
    <button class="btn btn-primary" @click="doQuery">Do query</button>

    <hr/>

    <main class="container">
      <div class="row">
        <div class="col-sm">
          <article class="chart-small"></article>
        </div>
        <div class="col-sm">
          <article class="chart-small"></article>
        </div>
        <div class="col-sm">
          <article class="chart-small"></article>
        </div>
      </div>

      <div class="row" style="margin-top: 32px;">
        <div class="col-12">
          <h3>Individual Metrics</h3>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <article class="chart-small" ref="chart-target"></article>
        </div>
        <div class="col-6">
          <article class="chart-small"></article>
        </div>
      </div>

      <div class="row">
        <div class="col-sm"><article class="chart-small"></article></div>
        <div class="col-sm"><article class="chart-small"></article></div>
        <div class="col-sm"><article class="chart-small"></article></div>
      </div>

      <div class="row">
        <div class="col-sm"><article class="chart-small"></article></div>
        <div class="col-sm"><article class="chart-small"></article></div>
        <div class="col-sm"><article class="chart-small"></article></div>
      </div>

    </main>

    <!-- <article id="chart-target" ref="chart-target"></article> -->
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */ 
  export default {
    async created () {
      this.loadName();
      this.gviz = await this.loadGoogleVisualization();

    },
    data() {
      return {
        name: 'Bob',
        gviz: null,
      }
    },

    methods: {
      async loadName() {
        console.log('Loads name')
        // await api.loadName();
      },

      doQuery(){
        // @see https://developers.google.com/chart/interactive/docs/reference#Query
        // @see https://github.com/ctd-tech/connector-app/blob/011ec3a267c6f0ef8f374ef17520c4e5c49251cf/src/services/google-charts/data-table-loader.js#L12
        const { Query, QueryResponse, QueryWrapper, CustomQuery, DataTable, Table } = this.gviz;
        // let query = new Query('https://docs.google.com/spreadsheets/d/1UytyZTU1NGpAQJ-kii1Aw3lvLz3B1irYn1dcvD3bUdw/edit#gid=0', {
        let query = new Query('https://docs.google.com/spreadsheets/d/1UytyZTU1NGpAQJ-kii1Aw3lvLz3B1irYn1dcvD3bUdw/edit?gid=0', {
          
        });
        // query.setQuery(`select A,B,C, D where D = 'Kanye West' `);
        // query.setQuery(`select A,B,C, D where D = 'Addie Bayer' `);
        query.setQuery(`select A,B,C,D`); 
        query.send(response=>{
          // how do we handle response
          if ( response.isError() ){
            throw new Error(`Error querying google sheet:`, response.getDetailedMessage());
          }
          console.log(`QueryResponse:`, response);
          let dt = response.getDataTable();
          console.log(`QueryResponse.getDataTable():`, dt);

          const chartTargetEl = this.$refs['chart-target'];

          let tableChart = new Table(chartTargetEl);
          tableChart.draw(dt, {
            // title: '', // ??
            showRowNumber: true,
            width: '100%',
            // height: '100%',
          })

        })
      },

      async loadGoogleVisualization(){
        return new Promise((resolve, reject)=>{
          // eslint-disable-next-line
          google.load('visualization', '1', {
            packages: ['corechart', 'table', /* 'controls' */],
            callback: (err)=>{
              if ( err ) return reject(err);
              // eslint-disable-next-line
              resolve(google.visualization);
            },
          })
        });
      }
    },
  }
</script>

<style scoped>
.chart-small{
  margin-top: 16px;
  width: 100%;
  height: 300px;
  background: white;
  border-radius: 12px;
  border: 1px solid gainsboro;
}
</style>