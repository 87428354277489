<template>
  <div class="tenant-list">
      <header>
        <span class="header-title">Student groups</span>
        <span class="header-actions">
          <a href="#"
            @click.prevent.stop="handleAddGroup"
            class="btn btn-link">
            +add student group
          </a>
        </span>
      </header>
      <ul class="scroll">
        <!-- <li v-for="i in 20" :key="i">hello {{i}}</li> -->
        <li v-for="gr in groups" :key="gr.id">
          <router-link :to="'/manage/student-groups/' + gr.id">
            {{gr.label}} - {{gr.tenant_uuid}}
          </router-link>
        </li>
      </ul>
  </div>
</template>

<script>
import swal from 'sweetalert'
export default {
  methods: {
    async handleAddGroup() {
      this.$emit('group_create', {
        tenant_uuid: this.tenant_uuid, // other arguments will be asked by event handler (eg .name)
      });
    }
  },
  props: {
    groups: {
      type: [Array],
      required: true,
      // what if it is null?
      // default: 
    },
    tenant_uuid: {
      type: String,
      required: true,
    },
  },
  
}
</script>

<style scoped>
.header-actions{
  flex-grow: 1;
  text-align: right;  
}
</style>