<template>
  <div class="cj-student-list__cell">
    <!-- {{col.label}} -->
    <!-- My exam: [{{col.id}}],
    My student: [{{student.name}}] -->
    <!-- {{attempt.id}} -->
    <span v-if="null === attempt">
      ...
    </span>
    <span v-else-if="false === attempt">
      <!-- - -->
      <router-link :to="`/student/${student.id}/exam/${col.id}/performance`" class="no-underline">
        -
      </router-link>
    </span>
    <span v-else :data-attempt-id="attempt.id" :class="{ 'cj-student-list__cell--highlight' : shouldHighlight(attempt) }">
      <!-- {{attempt.id}} -->
      <!-- [{{highlight}}] -->
      <!-- <router-link :to="'/student/' + student.id + '/score'"> -->
      <router-link class="cj-student-list__cell-link" :to="`/student/${student.id}/exam/${col.id}/performance`">
        {{attempt.ScorePercent}}
      </router-link>
      <i class="fa fa-info-circle xfa-2x" 
        @click="showAttemptSerialized" 
        style="color: lightgrey; margin-left: 6px; cursor: pointer;" 
        aria-hidden="true"></i>
    </span>
  </div>
</template>

<script>
// import { StudentRepository } from '../../services'
// eslint-disable-next-line
import { Student } from '../../../entities/student.ent'
import { AttemptRegistry } from '../../../services'
  export default {
    name: 'ExamCell2',
    created(){
      this.loadLastAttempt();
    },
    data() {
      return {
        attempt: null,
      }
    },
    methods: {
      loadLastAttempt() {
        /** @type {Student} */
        const student = this.student;
        const examId = this.col.id; // the the col.id supplied to ExamCell2 IS INDEED same as exam.id, but other columns may differ
        
        // ÷const { student } = this;
        // eslint-disable-next-line
          // debugger;
          this.attempt = AttemptRegistry.getBy({
            studentFullName: student.fullName,
            examId,
          }) || false;
        // console.log(`lastAttempts`)
        // StudentRepository.listStudents();
      },
      showAttemptSerialized(){
        // console.log(`Attempt:`, att);
        const attemptJson = JSON.stringify(this.attempt, null, 2);
        alert(`attemptJson:` + attemptJson);
        console.log(`attemptJson(id=${this.attempt.id})`, attemptJson);
      },
      shouldHighlight(attempt){
        // console.log(`Type of this.highlightFunction = ${typeof this.highlightFunction}`);
        if ( this.highlightFunction ){
          return this.highlightFunction(attempt);
        }
        else{
          // default logic
          return false; // do not highlight
          // return attempt.ScorePercent < 80;
        }
      },
    },
    props: {
      /** @type {Student} */
      student: {
        type: Object,
        default: ()=>{},
      },
      col: {
        type: Object,
        default: ()=>{},
      },
      // highlight: Boolean,
      highlightFunction: Function,
      // lastAttempts: Object,
    },

  }
</script>

<style scoped>
.cj-student-list__cell{
  white-space: nowrap;
}
.cj-student-list__cell > span {
  padding: 4px 8px;
  border-radius: 6px;
}
.cj-student-list__cell--highlight{
  /* background-color: #ff0000a6;; */
  background-color: orange;
}


/* utils */
.no-underline{
  text-decoration: none !important;
}

.cj-student-list__cell-link{
  color: black;
}
</style>