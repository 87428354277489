<template>
  <div class="xwidget-small cj-widget-attempts">
    <table v-if="haveAttempts" class="table xxtable-hover table-light xxtable-sm lea-table">
      <thead>
        <tr>
          <th>When</th>
          <th>Score</th>
          <th>Duration</th>
          <th>Questions Correct</th>
          <th>Questions Skipped</th>
          <th>Questions Incorrect</th>
          <!-- <th>Better than previous</th> -->
        </tr>
      </thead>
      <tbody>
        <tr v-for="(att,i) in attempts" :key="att.id" class="attempt-row" :class="{ 'is-passing' : is_passing(att) }">
          <td class="lea-table__cell-when">
            <!-- <router-link to="/attempt/:attempt_id/exam/:exam_id/attempt-detail"> -->
            <router-link :to="`/attempt/${att.id}/exam/${att.ExamId}/attempt-detail`">
            {{ pretty_date(att.TimeStartedAt) }}
            </router-link>
          </td>
          <td class="lea-table__cell-score">
            {{ att.ScorePercentInt }}%
            <span :class="[checkIsAttempt_A_betterThan_B(att, i+1).textColorClass]" title="Shows whether result is better than previous">
            {{ checkIsAttempt_A_betterThan_B(att, i+1).text }}
            </span>
          </td>
          <td class="lea-table__cell">{{ att.TimeDurationMinutes }}mins</td>
          <td class="lea-table__cell">{{ att.QuestionsAnsweredCorrectly }} / {{ att.QuestionsTotal }}</td>
          <td class="lea-table__cell">{{ att.QuestionsSkipped }}</td>
          <td class="lea-table__cell">{{ att.QuestionsAnsweredIncorrectly }}</td>
          <!-- <td class="lea-table__cell">
            
            <span :class="[checkIsAttempt_A_betterThan_B(att, i+1).textColorClass]">
            {{ checkIsAttempt_A_betterThan_B(att, i+1).text }}
            </span>
          </td> -->
        </tr>
      </tbody>
    </table>
    <div v-else-if="emptyAttempts">
      <h3 style="padding: 50px 32px; text-align: center;">Student did not attempt this exam yet</h3>
    </div>
    <pre v-else>Loading attempts...</pre>
  </div>
</template>

<script>
import { AttemptRegistry, AttemptRepository } from '../../services'
import { format, formatRelative } from 'date-fns'
import { Attempt } from '@/entities';

// import assert from 'assert'
export default {
  name: 'LastExamAttempts',
  computed: {
    isLoading() {
      return null === this.attempts;
    },
    emptyAttempts(){
      return this.attempts && (0 === this.attempts.length);
    },
    haveAttempts(){
      return this.attempts && this.attempts.length;
    }
  },
  created () {
    this.initialize();
  },
  data() {
    return {
      attempts: null,
    }
  },
  methods: {
    async initialize(){
      this.loadAttempts();
    },
    async loadAttempts() {
      // this.attempts = (await AttemptRepository.findByUser({ fullName: this.student.fullName }, { initiatedBy : 'LastExamAttemptsWidget#loadAttempts()'})) 
      const attempts = (await AttemptRepository.findBy({ studentFullName: this.student.fullName, examId: this.exam_id }, { initiatedBy : 'LastExamAttemptsWidget#loadAttempts()'})) 
      this.attempts = attempts.sort(Attempt.cmpByTimeStartedAtReverse);
      // this.attempts = await AttemptRepository.findAll({}, { initiatedBy : 'LastExamAttemptsWidget#loadAttempts()'});
      // this.attempts = AttemptRegistry.getBy({
      //   studentFullName: this.student.fullName,
      //   examId: this.exam_id,
      // })
      // assert(this.attempts, 'this.attempts is defined after loading');
      // assert(Array.isArray(this.attempts), 'this.attempts is an array'); // this may fail as those guys are proxies...
      // debugger;
      console.log(`Resulted in ${this.attempts.length} attempts`)
    },
    pretty_date: function(date) {
      return formatRelative(date, new Date());
      // return format(date, 'MMMM d');
    },
    is_passing: function(att){
      return att.ScorePercent > 80;
    },
    checkIsAttempt_A_betterThan_B(attA, indexB){
      
      if ( indexB < 0 ) return { text: '-', textColorClass: 'x' };
      if ( indexB >= this.attempts.length ) return { text: '-', textColorClass: 'x' };
      const attB = this.attempts[indexB];
      // return attA.ScorePercent > attB.ScorePercent ? 'A is better' : 'worse';
      if ( attA.ScorePercent > attB.ScorePercent ){
        
        return { 
          text: '▲',
          textColorClass: 'text-success',
        }
      }
      else{
        return { 
          text: '▼',
          textColorClass: 'text-danger',
        }
      }
    }
  },
  props: {
    student: Object,
    exam_id: String,
  },
  watch: {
    exam_id(newValue, oldValue) {
      this.initialize();
    },
    student(newValue, oldValue){
      this.initialize();
    }
  },
  
}
</script>

<style scoped>
.cj-widget-attempts{
  display: block;
  /* overflow-y: scroll; */
  /* height: 600px; */
}
.attempt-row.is-passing > td{
  background-color: #82f282;
}
.lea-table__cell{
  text-align: center;
}
.lea-table__cell-when{
  white-space: nowrap;
}

.lea-table__cell-score{
  text-align: right;
  white-space: nowrap;
}

.lea-table > thead > tr > th{
  vertical-align: middle;
  text-align: center;
}
</style>