<template>
  <main class="container co-stack" style="height: 100%;">
    <h3>Manage Organizations <span v-if="currentTenant">({{currentTenant.title}})</span></h3>
  <controls>
      <button type="button" class="btn btn-primary" @click="handleCreateTenant">New Organization</button>
      <!-- <button type="button" class="btn btn-secondary">Secondary</button>
      <button type="button" class="btn btn-success">Success</button>
      <button type="button" class="btn btn-danger">Danger</button>
      <button type="button" class="btn btn-warning">Warning</button>
      <button type="button" class="btn btn-info">Info</button>
      <button type="button" class="btn btn-light">Light</button>
      <button type="button" class="btn btn-dark">Dark</button>

      <button type="button" class="btn btn-link">Link</button>
      <div class="btn-group" role="group" aria-label="Basic example">
        <button type="button" class="btn btn-primary">Left</button>
        <button type="button" class="btn btn-primary">Middle</button>
        <button type="button" class="btn btn-primary">Right</button>
      </div>         -->
    </controls>
    <div cardboard class="co-container">
      <section paper class="co-panel-50pc">
        <TenantList v-if="tenants"
          @onTenantSelected="handleTenantSelected"
          @tenantMutation="handleTenantMutation"
          :tenants="tenants"
          />
        <h3 v-else>
          No tenants loaded...
        </h3>
      </section>
      <section paper2 class="co-panel-50pc flex">
        <TenantMemberList cardboard v-if="membersOfTenant" :members="membersOfTenant" class="flex-grow vertical-33pc"/>
        <span v-else>Plase select tenant to show its member users</span>

        <TenantGroupList 
          v-if="groupsOfTenant"
          @group_create="handleGroupCreate"
          :groups="groupsOfTenant" 
          :tenant_uuid="currentTenant.uuid"
          cardboard2 
          class="flex-grow vertical-33pc"/>
        <span v-else>Please select tenant to show its groups</span>

        <TenantInvitationList 
          v-if="invitationsOfTenant" 
          :invitations="invitationsOfTenant" 
          :tenant="currentTenant"
          cardboard3 
          class="flex-grow vertical-33pc"/>
        <span v-else>Please select tenant to show its invitations</span>
      </section>

    </div>

    <button @click="handleFloatieToggle" class="btn btn-secondary">Toggle floatie</button>

    <FloatiePopup :isShown="isFirstFloatieShown"></FloatiePopup>

    
  </main>
</template>

<script>
import FloatiePopup from '../../components/floatie/FloatiePopup.vue'
import TenantList from './components/TenantList.vue'
import TenantGroupList from './components/TenantGroupList.vue'
import TenantInvitationList from './components/TenantInvitationList.vue'
import TenantMemberList from './components/TenantMemberList.vue'

import { LIST_TENANTS } from './gql/list-tenants.gql'
import { MEMBERS_OF_TENANT } from './gql/members-of-tenant.gql'
import { GROUPS_OF_TENANT } from './gql/groups-of-tenant.gql'
import { INVITATIONS_OF_TENANT } from './gql/invitations-of-tenant.gql'
import { MUTATION__ADD_TENANT } from './gql/add-tenant.gql'
import { MUTATION__CREATE_STUDENT_GROUP } from './gql/create-group.gql'
import swal from 'sweetalert'
const $ = (target, label)=> {
  if ( label ) {
    console.warn(label, target);
  }
  else{
    console.warn(target);
  }
  return target;
}
export default {
  apollo: {
    // tenants: LIST_TENANTS,
    tenants: {
      query: LIST_TENANTS,
      update: function(data){
        // making a copy and allowing it to be extended..?
        return data.tenants.map(tn=>({...tn}))
      },
    },
    // allTenants: {
//  Missing allTenants attribute on result 
// {listTenants: Array(6)}
// listTenants
// :
    //   query: LIST_TENANTS,
    //   // update: function(){}
    // }
  },
  components: {
    FloatiePopup,
    TenantList,
    TenantGroupList,
    TenantInvitationList,
    TenantMemberList,
  },
  
  data() {
    return {
      isFirstFloatieShown: false,
      // Tenant details
      groupsOfTenant: null,
      membersOfTenant: null, // what does that mean? 
      invitationsOfTenant: null, // just supply some empty


      currentTenant: null, // why didn't I add this variable earlier?
    }
  },
  methods: {
    async createNewTenant(inputTenant){
      const { title, kind } = inputTenant;
      // how to call a mutation?
      const mutationResult = $(await this.$apollo.mutate({
        mutation: MUTATION__ADD_TENANT,
        variables: {
          name: title,
          // kind,
        },
      }));

      return mutationResult.data.newTenant;
      
      // const uuid =  `tn${Math.random()}`;
      // const id = uuid;
      // // TODO: need to implement...
      // return {
      //   ...inputTenant,
      //   uuid,
      //   id: uuid,
      // };
    },
    async createNewStudentGroup(name, tenant_uuid){
      const mutationResult = await this.$apollo.mutate({ 
        mutation: MUTATION__CREATE_STUDENT_GROUP,
        variables: {
          name,
          // tenant_uuid,
          tenantUuid: tenant_uuid,
        },
      });
      return toGroupPresenter(mutationResult.data.studentGroup);
    },
    async handleCreateTenant(){
      const inputTenant = {
        title: '', // string
        kind: '', // string
      };
      // await swal('Hello');
      // allows empty strings (just press //OK/// ),
      // on close returns null
      inputTenant.title = await swal(`> eg. 'Florida School' or 'Arizona College' `, {
        title: 'Input name of the new organization',
        content: 'input',
        closeOnEsc: true,
        closeOnClickOutside: false, // otherwise select and drag will close it 
        buttons: [
          // 'Another button',  // What is this going to be? only 2 buttons are allowed
          'Cancel',  // this allegedly will cancel (return null)
          'Next', // this allegedly will confirm always
        ],
        
      });
      if ( null === inputTenant.title ){
        return;
      }
      inputTenant.kind = await swal('> Kind of the tenant:', {
        content: 'input',
        title: 'Input tenant type',
        text: `This is just infor for yourselves, can be one word like: school, college, university or any other keyword`,
        closeOnEsc: true,
        closeOnClickOutside: false, // otherwise select and drag will close it 
        buttons: [
          // 'Another button',  // What is this going to be? only 2 buttons are allowed
          'Cancel',  // this allegedly will cancel (return null)
          'Create Tenant', // this allegedly will confirm always
        ],        
      });
      if ( null === inputTenant.kind ){
        return;
      }

      alert('Before sanitizing: ' + JSON.stringify(inputTenant,null,2));


      inputTenant.title = inputTenant.title && inputTenant.title.trim();
      inputTenant.kind = inputTenant.kind && inputTenant.kind.trim();

      alert('After sanitizing: ' + JSON.stringify(inputTenant,null,2));

      if ( !inputTenant.title ){
        return alert(`You must input title for the new tenant`);
      }
      if ( !inputTenant.kind ){
        return alert(`You must input kind for the new tenant`);
      }
      
      try{
        const newTenant = await this.createNewTenant(inputTenant);
        // what do we do with the newTenant
        // we just have to update the store which holds this specific tenant... 
        this.tenants.push(newTenant);

      }
      catch(err){
        // what kind of error do we put here? 
        console.error(`Error creating new tenant with args`, inputTenant, err);
        alert(`Error creating new tenant: ${err.message}`);
      }
      
      
    },
    async handleCreateInvitation(){
      await swal('Creating invitation'); // probably need username here
    },
    handleFloatieToggle() {
      this.isFirstFloatieShown = !this.isFirstFloatieShown;
    },
    async handleGroupCreate(ev){
      console.log('group_create event: ' + JSON.stringify(ev, null,2));
      const { tenant_uuid } = ev;
      let name = await swal(`> eg. 'Vet Students Y2022' or 'Hygienists Y2023'`, {
        title: 'Type the name of the new student group',
        content: 'input',
        closeOnEsc: true,
        closeOnClickOutside: false,
        buttons: [
          'Cancel', // returns null
          'Next',
        ],
      });

      if ( !name || !name.trim() ){
        return;
      }
      debugger;
      // how can I create a group here? 
      // i need to call the mutation 
      // and later I simply add this to the list of groups for this tenant? 
      const newStudentGroup = await this.createNewStudentGroup(name, tenant_uuid);
      
      this.groupsOfTenant.push(newStudentGroup);
      await swal(
        `Group created!`, 
        `You have created group “${newStudentGroup.name}“!\n Now you can manage and add students to this group!`,
        'success'
      );
    },
    handleTenantSelected(tenant){
      // what do we do here?
      // reset the loading state...
      // I should trigger loading of the children of this thingy here...
      // how can I do that? 
      // alert('Handling tenant');
      console.log(`clicked on tenant: `,tenant);
      this.currentTenant = tenant;
      const catchFetchingError = (err)=>{
        console.error(err);
        console.error(`Error performing fetching of tenant details: ${err.message}`);
        throw err; // do we rethrow?
      };

      this.fetchGroupsOfTenant(tenant.uuid).then(groups=>{
        this.groupsOfTenant = groups;
      }).catch(catchFetchingError);

      this.fetchMembersOfTenant(tenant.uuid).then(membersOfTenant=>{
          this.membersOfTenant = membersOfTenant;
      }).catch(catchFetchingError);

      this.fetchInvitationsOfTenant(tenant.uuid).then(invitationsOfTenant=>{
        this.invitationsOfTenant = invitationsOfTenant;
      }).catch(catchFetchingError);
      // TODO: fetch invitations too
    },
    handleTenantMutation(mutEvent){
      // ALL MUTATIONS MUST BE SYNC!!!! 
      // As they're triggered as sync events and hence cannot be async.
      // As whoever triggered them expects them to complete by the time they return
      const collectionMutations = {
        clearSelected: ()=>{
          this.tenants.forEach((tn) => (tn.__isSelected = false));
        },
      };
      const itemMutations = {
        setSelected: ({ tenant_uuid })=>{
          const destTenant = this.tenants.find((tn) => tn.uuid === tenant_uuid);
          if (!destTenant) {
            console.warn(
              `Attempting to select non-existing tenant with uuid(${tenant_uuid})`
            );
            return false;
          }
          destTenant.__isSelected = true;
          return true; // whether marking succeeded     
        },
      };
      const { op, subject } = mutEvent;
      if ( 'collection' === subject) {
        collectionMutations[op](mutEvent); // runs the mutation... 
      }
      else if ( 'item' === subject){
        itemMutations[op](mutEvent); 
      }
      else {
        throw new Error(`Unsupported subject for {tenant mutation}: '${subject}'`);
      }

      // no return value, as 'cos this is trigered via $emit, there's no
      // way to return the value
    },
    async fetchMembersOfTenant(tenant_uuid){
      const apolloQueryResult = await this.$apollo.query({
        query: MEMBERS_OF_TENANT,
        variables: {
          tenantUuid: tenant_uuid,
        },
      });
      console.log(`apolloQueryResult: `, apolloQueryResult);
      if ( apolloQueryResult.error ){
        console.error(apolloQueryResult.error);
        throw new Error(apolloQueryResult.error.name);        
      }
      const { members } = apolloQueryResult.data;
      return members.map(user=>{
        return {
          ...user,
          id: user.uuid,
          label: user.title,
        };
      }); 
    },
    async fetchGroupsOfTenant(tenant_uuid){
      // fetching tenant Groups... 
      const { data, error } = await this.$apollo.query({
        query: GROUPS_OF_TENANT,
        variables: {
          tenantUuid: tenant_uuid,
        },
        fetchPolicy: 'no-cache',
      });

      if ( error ) {
        console.error(error);
        throw new Error(error.name);
      }
      const { groupsOfTenant } = data;
      return groupsOfTenant.map(toGroupPresenter)
    },
    async fetchInvitationsOfTenant(tenant_uuid){
      const { data, error } = await this.$apollo.query({
        query: INVITATIONS_OF_TENANT,
        variables: {
          tenantUuid: tenant_uuid,
        }
      });
      if ( error ){
        console.error(error);
        throw new Error(error.name);
      }
      const { invitations } = data;
      return invitations.map(inv=>{
        return {
          ...inv,
          label: `${inv.status} for ${inv.destination_email}`,
          id: inv.uuid,
        }
      })
    }
  },
  async mounted(){
  }
  
}


function toGroupPresenter(group){
  return {
    ...group,
    label: group.name,
    id: group.uuid,
  };
}
</script>

<style scoped>

main.co-stack{
  height: 100%;
  display: flex;
  flex-direction: column;
}

.co-container{
  display: flex;
  height: 100%;
}

.co-panel-50pc{
  width: 50%;
}

.co-panel-33pc{
  width: 33%;
}

.co-panel-25pc{
  width: 25%;
}

.co-panel-75pc{
  width: 25%;
}

controls{
  margin: 16px 0;
}

.flex-grow{
  flex-grow: 1;
}

.vertical-33pc{
  height: 33%;
}

.flex{
  display: flex;
  flex-direction: column;
}

</style>