import { createApp, inject, ref, reactive } from 'vue'
import App from './App.vue'
import Amplify from 'aws-amplify'
import awsExports from './aws-exports'
Amplify.configure(awsExports)
import AmplifyVue from '@aws-amplify/ui-vue'
import router from './router'
import Collapse from 'bootstrap/js/dist/collapse'
import Dropdown from 'bootstrap/js/dist/dropdown'

// import { ApolloClient } from 'apollo-boost'
// import VueApollo from 'vue-apollo'
import { createApolloProvider } from '@vue/apollo-option'

import { apolloClient } from './apollo-client'

const apolloProvider = createApolloProvider({
  defaultClient: apolloClient,
  defaultOptions: {
    $query: {
      fetchPolicy: 'no-cache',
    },
  },
});




import { init }  from './services/init'
const ex007 = reactive({ 
  id: 'ex007', 
  counter: 0,
  // title: 'Ex 007', 
  get title(){
    return `Ex ${this.counter}`;
  },
  compactTitle: 'Ex007',
});

// const ex007 = { 
//   id: 'ex007', 
//   counter: 0,
//   // title: 'Ex 007', 
//   get title(){
//     return `Ex ${this.counter}`;
//   },
//   compactTitle: 'Ex007',
// };

const gExams = reactive([ex007]);


init({
  gExams,
}).then(()=>{

  // @see https://masteringjs.io/tutorials/vue/reactivity


  // const apolloProvider = new VueApollo({
  //   defaultClient: new ApolloClient({
  //     // uri: 'http://localhost:4001', // TODO: is this the right GraphQL endpoint?
  //     uri: 'http://localhost:4000', // Our dummy SessionsAPI lives here
  //   })
  // });


  const app = createApp(App);
  
  // this doesn't seem to have any effect
  // app.config.compilerOptions.isCustomElement = (tag)=> {
  //   console.warn(`Checking custom tag <${tag}>`);
  //   return 'controls' === tag;
  // };
    
  app.provide('gExams', gExams);
  app.use(AmplifyVue)
  app.use(router);
  // app.use(VueApollo); // that's legacy crap(from ApolloBoost)
  app.use(apolloProvider); // https://v4.apollo.vuejs.org/guide-option/setup.html#_2-create-the-apollo-client
  // app.config.isCustomElement = tag => tag === 'controls'; 
  // app.config.
  app.mount('#app')

  // On isCustomElement() @see https://stackoverflow.com/a/73215718/1168382

  // // this doesn't work with inject neither
  // window.iv = setInterval(()=>{
  //   // const gExams = inject('gExams');
  //   // const ex = gExams[0];
  //   gExams.push(gExams[0]);
  //   console.log(`Counter: `, ++ex007.counter); // this won't be reactive.
  // }, 1000);

});

