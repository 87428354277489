<template>
  <div class="cj-apollo-graphql">
    <h3 v-if="!isEmpty(sessions)">We are querying for session data from GraphQL...</h3>
    <ul v-if="!isEmpty(sessions)">
      <li v-for="sess of sessions" v-bind:key="sess.id">[{{sess.title}}] - {{sess.id}}</li>
    </ul>

    <h3>We are querying for category data from GraphQL...</h3>
    <ul v-if="!isEmpty(categories)">
      <li v-for="cat of categories" v-bind:key="cat.id">[{{cat.title}}] - {{cat.id}}</li>
    </ul>
  </div>
</template>

<script>
import gql from 'graphql-tag'

const LIST_SESSIONS = gql`query sessions {
    sessions: listSessions(room:Sol) {
      id
      title
      room
      isFavorite
      # speakers {
      #   name
      #   bio
      # }
    }
}`;

const LIST_CATEGORIES = gql`query Categories_Query{
  categories: listCategories {
    id
    title
    imageFilename
    imageUrl
  }
}`;

export default {
  name: 'ApolloGraphQL',
  apollo: {
    // listSessions: LIST_SESSIONS, // name should(advisable?) to be same as in .data
    // sessions: LIST_SESSIONS, // listSessions root query is not available anymore on our api
    categories: LIST_CATEGORIES,
  },
  // props: {
  //   hello: 'World',
  // }
  async created () {
    // console.log(`this.$apollo.queries.sessions`,this.$apollo.queries.sessions);
    // await this.loadSessions();
  },
  data() {
    return {
      sessions: [],
      categories: [],
    }
  },

  methods: {
    async loadSessions() {

      // await doLoadSessionsAsync(); 
      const apolloQueryResult = await this.$apollo.query({
        query: LIST_SESSIONS,
      });

      console.log(`apolloQueryResult: `, apolloQueryResult);

      if ( apolloQueryResult.error ){
        console.error(apolloQueryResult.error);
        throw new Error(apolloQueryResult.error.name);
        return;
      }
      const { sessions } = apolloQueryResult.data;

      debugger;

    },
    isEmpty(t){
      return 0 === t.length;
    }
  },
  
}
</script>

<style scoped>

</style>